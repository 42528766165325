<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="false"
    ></b-loading>
    <transition name="fade">
      <div class="background">
        <div class="background-img"
             :style="{ backgroundImage: 'url(' + require('@/assets/background.png') + ')' }">
          <img alt="top-text-image" src="../../assets/top-text.svg" class="background-text">
        </div>
        <div class="center-form">
          <div style="display: flex; flex-direction: column; align-items: center">
            <div class="box is-flex is-flex-direction-column is-flex-wrap-wrap is-align-content-center"
                 style="padding: 32px; width: 440px; height: 300px; margin-bottom: 16px">
              <b-image class="logo-form" :src="require('@/assets/logo-vert.png')"></b-image>
              <div v-if="!checkFinished" class="is-size-3 has-text-weight-bold has-text-centered"
                   style="margin-top: 32px">Please wait...
              </div>
              <div v-else class="is-size-5 has-text-weight-normal has-text-centered" style="margin-top: 32px">You don't
                have permission to any content of Realite Admin. Please contact our support <span
                    class="has-text-weight-bold">support@realtimetraffic.com.au</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import Vue from "vue";
import Utils, {isAllowed} from "@/utils/utils";
import router from "@/router";
import store from "@/store";

export default {
  name: "start-page",
  data() {
    return {
      isLoading: true,
      checkFinished: false
    }
  },
  async created() {

  },
  async mounted() {
    await this.checkAuth()
  },
  methods: {
    async checkAuth() {
      this.isLoading = true
      this.checkFinished = false
      await this.$auth.currentAuthenticatedUser().then(async (user) => {
        this.$store.commit('saveUserData', user)
        if (this.$store.getters.getMe === null) {
          await this.$store.dispatch('getMe')
        }
        if(this.$store.getters.getMe){
          let appIndex = this.$store.getters.getMe.apps.findIndex(app => app.name === Utils.appName)
          if(appIndex === -1){
            window.location.replace(Vue.prototype.$env.VUE_APP_AUTH_URL + '/choose-product')
            return
          }
        }
        this.isLoading = false
        if (isAllowed(this.$store.getters.getMe, 'project:list')) {
          await this.$router.push('projects')
        } else if (isAllowed(this.$store.getters.getMe, 'system-settings:get')) {
          await this.$router.push('settings')
        } else if (isAllowed(this.$store.getters.getMe, 'uac:get')) {
          await this.$router.push('uac')
        } else {
          this.checkFinished = true
        }

      }).catch((error) => {
        console.log('Auth error', error)
        this.isLoading = false
        window.location.replace(Vue.prototype.$env.VUE_APP_AUTH_URL)
      });
    },
  }
}
</script>

<style scoped lang="scss">
.right-side {
  padding: 20px;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-form {
  width: 440px;
  min-height: 570px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>