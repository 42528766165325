<template>
  <div class="container bottom-shadow" style="max-width:none" :key="this.$store.getters.getMe.permissions.length">
    <b-navbar :centered="true" style="height:90px; margin-left: 4vh; margin-right: 1vh;" type="is-default"
              :active="false">
      <template #brand v-if="isSubCategory">
        <div @click="back" class="mt-auto mb-auto">
          <b-icon size="is-medium" icon="arrow-left" style="cursor: pointer"></b-icon>
        </div>
        <h4 class="is-size-4 ml-5 has-text-primary" style="margin-top: 24px">{{ topBarTitle }}</h4>
      </template>
      <template #brand v-else>
        <img alt="Logo" src="@/assets/logo.png" class="ml-1" style="height: 50px; margin-top: 20px; cursor: pointer"
             @click="openLink('https://realtimetraffic.com.au/')">
      </template>
      <template #start v-if="!isSubCategory">
        <router-link v-for="(item,index) in routes" :key="index" :to="item.path"
                     :class="{'navbar-item':true,'menuItemBold':activePath === item.path }">
          {{ item.meta.title }}
        </router-link>
      </template>

      <template #end>
        <div class="is-flex">
<!--        <mqtt-service></mqtt-service>-->
          <web-socket></web-socket>
        <user-dropdown style="margin-top: 8px;"/>
        </div>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import userDropdown from "@/components/user-dropdown/user-dropdown.vue";
import WebSocket from "@/components/web-socket/web-socket.vue";

export default {
  name: "top-bar",
  components: {
    WebSocket,
    userDropdown
  },
  mounted() {
    this.checkRouterData(this.$router.currentRoute)
  },
  data() {
    return {
      isEditProfileActive: false,
      isSubCategory: false,
      topBarTitle: '',
      activePath: ''
    };
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
    back() {
      this.$router.back()
    },
    checkRouterData(to) {
      this.isSubCategory = to.meta.isSubCategory
      this.activePath = to.path
      document.title = to.meta.product + ' - ' + to.meta.title;
      console.log('Check route Top bar title:', this.topBarTitle)
      setTimeout(() => {
        this.topBarTitle = to.meta.topBarTitle
      }, 2000)
    }
  },
  watch: {
    /**
     * Get new menu data when route is changed
     * @param to
     * @param from
     */
    $route(to, from) {
      this.checkRouterData(to)
    }
  },
  computed: {
    role() {
      return this.$store.getters.getUserRole
    },
    routes() {
      let permNames = []
      if (this.$store.getters.getMe && this.$store.getters.getMe.permissions && this.$store.getters.getMe.permissions.length > 0) {
        permNames = this.$store.getters.getMe.permissions.map(perm => perm.name)
      }

      let routes = this.$router.getRoutes()
          .filter(route => route.meta.hasMenu === true &&
              permNames.includes(route.meta.permission)
              && !route.meta.isSubCategory)
      //Sort by menuPriority meta
      routes = routes.sort(function (a, b) {
        return a.meta.menuPriority - b.meta.menuPriority
      });
      return routes
    },
  }
}
</script>

<style scoped lang="scss">
.menuItemBold {
  font-weight: bold !important;
}
</style>