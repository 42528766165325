<template>
  <div class="box">
    <div class="has-text-weight-semibold is-size-5">{{ getDateTime(tableData.date) }}</div>
    <table style="width: 100%" class="mt-3">
      <tr class="is-size-7 has-text-weight-light">
        <th>STAGE NAME</th>
        <th>DIRECTION</th>
        <th>USER CLASS</th>
        <th>AI COUNT</th>
        <th>MANUAL COUNT</th>
        <th>%</th>
      </tr>
      <template v-for="(stage, stageIndex) in tableData.stages">
        <template v-for="(direction, dirIndex) in stage.directions">
          <template v-for="(data, index) in direction.data">
            <tr :key="index.toString() + dirIndex.toString() + stageIndex.toString()"
                :class="index % 2 === 0 ? 'evenRow' : ''" class="has-text-weight-semibold">
              <td v-if="index === 0 && dirIndex === 0" :rowspan="getRowCountForStage(stage)"
                  class="middle has-text-weight-semibold is-size-5" style="width: 20%">{{ stage.name }}
              </td>
              <td v-if="index === 0" :rowspan="getDirectionsCountForStage(direction)"
                  :style="getDirectionBackground(direction)" class="middle has-text-weight-semibold is-size-6"
                  style="color: white; width: 20%">{{ direction.name }}
              </td>
              <td style="width: 20%">{{ data.userClass }}</td>
              <td style="width: 15%">{{ data.aiCount }}</td>
              <td style="width: 15%">{{ data.manualCount }}</td>
              <td style="width: 10%">{{ data.percentage }}</td>
            </tr>
          </template>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
import {getDateTime} from "@/utils/utils";

export default {
  name: "validation-data-table",
  props: {
    validationCount: {
      type: Object
    }
  },
  data() {
    return {
      /*      tableData:
                {
                  date: new Date().toISOString(),
                  stages: [
                    {
                      name: 'Stage 1',
                      directions: [
                        {
                          name: 'Northbound',
                          data: [
                            {
                              userClass: 'Light Vehicle',
                              aiCount: 45,
                              manualCount: 44,
                              percentage: -2.03
                            },
                            {
                              userClass: 'Heavy Vehicle',
                              aiCount: 33,
                              manualCount: 32,
                              percentage: -1.0
                            },
                            {
                              userClass: 'Small Vehicle',
                              aiCount: 67,
                              manualCount: 66,
                              percentage: -3.0
                            },
                            {
                              userClass: 'Bus',
                              aiCount: 15,
                              manualCount: 15,
                              percentage: 0.0
                            }
                          ]
                        },
                        {
                          name: 'Southbound',
                          data: [
                            {
                              userClass: 'Light Vehicle',
                              aiCount: 48,
                              manualCount: 34,
                              percentage: -2.03
                            },
                            {
                              userClass: 'Heavy Vehicle',
                              aiCount: 34,
                              manualCount: 34,
                              percentage: -1.0
                            },
                            {
                              userClass: 'Small Vehicle',
                              aiCount: 70,
                              manualCount: 70,
                              percentage: 0.0
                            },
                            {
                              userClass: 'Bus',
                              aiCount: 25,
                              manualCount: 25,
                              percentage: 0.0
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: 'Stage 2',
                      directions: [
                        {
                          name: 'Northbound',
                          data: [
                            {
                              userClass: 'Light Vehicle',
                              aiCount: 45,
                              manualCount: 32,
                              percentage: -2.03
                            },
                            {
                              userClass: 'Heavy Vehicle',
                              aiCount: 33,
                              manualCount: 32,
                              percentage: -1.0
                            },
                            {
                              userClass: 'Small Vehicle',
                              aiCount: 65,
                              manualCount: 68,
                              percentage: -3.0
                            },
                            {
                              userClass: 'Bus',
                              aiCount: 15,
                              manualCount: 15,
                              percentage: 0.0
                            }
                          ]
                        },
                        {
                          name: 'Southbound',
                          data: [
                            {
                              userClass: 'Light Vehicle',
                              aiCount: 48,
                              manualCount: 34,
                              percentage: -2.03
                            },
                            {
                              userClass: 'Heavy Vehicle',
                              aiCount: 34,
                              manualCount: 34,
                              percentage: -1.0
                            },
                            {
                              userClass: 'Small Vehicle',
                              aiCount: 70,
                              manualCount: 70,
                              percentage: 0.0
                            },
                            {
                              userClass: 'Bus',
                              aiCount: 25,
                              manualCount: 25,
                              percentage: 0.0
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }*/
    }
  },
  mounted() {
    console.log('Table Data: ', this.validationCount)
  },
  methods: {
    getDateTime(date) {
      return getDateTime(date)
    },
    getRowCountForStage(stage) {
      let stageRowsCount = 0
      if (stage && stage.directions && stage.directions.length > 0) {
        stage.directions.forEach(direction => {
          if (direction.data && direction.data.length > 0) {
            stageRowsCount += direction.data.length
          }
        })
      }
      return stageRowsCount
    },
    getDirectionsCountForStage(direction) {
      if (direction && direction.data && direction.data.length > 0) {
        return direction.data.length
      }

      return 0
    },
    getDirectionBackground(direction) {
      //(Total AI Count - Total Manual Count) / Total Manual Count

      console.log('Direction: ', direction)

      if (direction && direction.data) {
        let manualCounts = 0
        let aiCounts = 0
        direction.data.forEach(dt => {
          if (dt.manualCount && dt.manualCount > 0) {
            manualCounts += dt.manualCount
          }
          if (dt.aiCount && dt.aiCount > 0) {
            aiCounts += dt.aiCount
          }
        })

        if (manualCounts > 0 && aiCounts > 0) {
          let percentage = ((aiCounts - manualCounts) / aiCounts) * 100
          percentage = Math.abs(percentage)
          if (percentage < 5) {
            return 'background: #18AA7E;'
          } else if (percentage <= 10) {
            return 'background: #EEC832'
          } else if (percentage > 10) {
            return 'background: #FF2B0F;'
          } else {
            return 'color: black'
          }
        }
      }
      return 'color: black'
    }
  },
  computed: {
    tableData() {

      let fakeData = this.validationCount

      let tableData = {}
      if (fakeData) {
        tableData.date = Object.keys(fakeData)[0]
        tableData.stages = []
        let stageNames = Object.keys(fakeData[tableData.date])
        if (stageNames && stageNames.length > 0) {
          stageNames.forEach(stageName => {
            let stage = {name: stageName, directions: []}
            let directionNames = Object.keys(fakeData[tableData.date][stageName])
            if (directionNames && directionNames.length > 0) {
              directionNames.forEach(dirName => {
                let direction = {
                  name: dirName,
                  data: []
                }

                let userClassNames = fakeData[tableData.date][stageName][dirName].userClass
                let aiCount = fakeData[tableData.date][stageName][dirName].ai_count
                let manualCount = fakeData[tableData.date][stageName][dirName].manual
                if (userClassNames && userClassNames.length > 0) {

                  for (let i = 0; i < userClassNames.length; i++) {
                    let percents = manualCount[i] < aiCount[i] ? (aiCount[i] - manualCount[i]) / aiCount[i] * 100.0 : (aiCount[i] - manualCount[i]) / manualCount[i] * 100.0
                    percents = parseFloat(percents.toFixed(2))
                    let userClass = {
                      userClass: userClassNames[i],
                      aiCount: aiCount[i],
                      manualCount: manualCount[i],
                      percentage: percents
                    }
                    direction.data.push(userClass)
                  }
                }


                stage.directions.push(direction)

              })
            }

            tableData.stages.push(stage)
          })
        }
      }


      console.log('Table Data', tableData)
      return tableData

    }
  }
}
</script>

<style scoped lang="scss">
table {
  border: 1px solid #c4c4c4;
  border-collapse: collapse;
  padding: 8px 8px 8px 8px;
}

td {
  border: 1px solid #e5e5e5;
  padding: 4px 8px 4px 8px;
  font-size: 14px;
}

th {
  background: rgba(24, 170, 126, 0.35);
  border: none;
  padding: 8px 8px 8px 8px;
  color: rgba(0, 0, 0, 0.65)
}

.middle {
  text-align: center;
  vertical-align: middle;
  background: #ffffff;
}

.evenRow {
  background: #f1f1f1;
}
</style>