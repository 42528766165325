<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="50%" scroll="keep" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">
            {{ isUpdateMode ? 'Update Application' : 'Create New Application' }}</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, null)"/>
        </header>
        <section class="modal-card-body p-0 pt-5 pl-3 pr-3 pb-6">
          <b-field label="Name *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="app.name" rounded required ref="name"
                     validation-message="Required filed"></b-input>
          </b-field>
          <b-field label="URL *" label-position="on-border" expanded class="mt-5">
            <b-input v-model="app.url" rounded required ref="url"
                     validation-message="Required filed"></b-input>
          </b-field>
          <b-field label="Current Version" label-position="on-border" expanded class="mt-5">
            <b-input v-model="app.version" rounded ref="version"></b-input>
          </b-field>
          <b-field label="Icon * (without with and height property)" label-position="on-border" expanded class="mt-5">
            <b-input v-model="app.icon" rounded required ref="icon" type="textarea"
                     validation-message="Required filed"></b-input>
          </b-field>

        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary is-fullwidth ml-2 mr-2" @click="onActionClicked" rounded
                    :disabled="!isFormValid()" :key="refresh">{{ isUpdateMode ? 'UPDATE APP' : 'CREATE APP' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "add-change-app",
  data() {
    return {
      isLoading: false,
      modalActive: false,
      refresh: false,
      isUpdateMode: false,
      app: {
        id: null,
        name: null,
        icon: null,
        url: null,
        version: null
      }
    }
  },
  methods: {
    ...mapActions({
      addApp: 'uacModule/addApp',
      updateApp: 'uacModule/updateApp'
    }),
    async onActionClicked() {
      this.isLoading = true
      let payload = {
        name: this.app.name,
        url: this.app.url,
        icon: this.app.icon,
        version: this.app.version
      }
      console.log('Add app payload: ', payload)
      if (this.isUpdateMode) {
        payload.id = this.app.id
        let response = await this.updateApp(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Application successfully updated', 'is-success')
          this.show(false, null)
        }
      } else {
        let response = await this.addApp(payload)
        if (response instanceof Error) {
          this.showToast(response.message, 'is-danger')
        } else {
          this.clearFields()
          this.showToast('Application successfully created', 'is-success')
          this.show(false, null)
        }
      }

      this.isLoading = false
    },
    show(show, app) {
      if (app) {
        this.app.id = app._id
        this.app.name = app.name
        this.app.url = app.url
        this.app.icon = app.icon
        this.app.version = app.version
        this.isUpdateMode = true
        setTimeout(() => {
          this.refresh = true
        }, 50)
      }
      this.modalActive = show
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    clearFields() {
      this.app.id = null
      this.app.name = null
      this.app.url = null
      this.app.icon = null
      this.app.version = null
      this.refresh = false
      this.isUpdateMode = false
    },
    isFormValid() {
      if (this.$refs.name && this.$refs.name.value && this.$refs.url && this.$refs.url.value && this.$refs.icon && this.$refs.icon.value) {
        return this.$refs.name.isValid && this.$refs.url.isValid && this.$refs.icon.isValid
      }
      return false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
}
</script>

<style scoped>

</style>