import { render, staticRenderFns } from "./auto-validation-speed-chart.vue?vue&type=template&id=585bce27&scoped=true"
import script from "./auto-validation-speed-chart.vue?vue&type=script&lang=js"
export * from "./auto-validation-speed-chart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585bce27",
  null
  
)

export default component.exports