<template>
  <div>
    <div v-if="calibrations && calibrations.length > 0">
      <div v-for="calibration in calibrations" :key="calibration._id">
        <div class="box mb-2 p-3">
          <div class="columns m-0 p-0 pl-2">
            <div class="column m-0 p-0">
              <div class="m-0">
                <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">CALIBRATION ID</div>
                <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{ calibration._id }}</div>
              </div>
            </div>
            <div class="column m-0 p-0">
              <div class="m-0">
                <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">VALID FROM</div>
                <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{
                    getDate(calibration.validFrom)
                  }}
                </div>
              </div>
            </div>
            <div class="column m-0 p-0">
              <div class="m-0">
                <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">VALID TO</div>
                <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{
                    getDate(calibration.validTo)
                  }}
                </div>
              </div>
            </div>
            <div class="column m-0 p-0">
              <div class="m-0">
                <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">STATUS</div>
                <div class="has-text-black has-text-weight-semibold" style="line-height: 1">{{
                    calibration.status
                  }}
                </div>
              </div>
            </div>
            <div class="column is-flex m-0 p-0">
              <div class="ml-auto is-flex">
                <b-tooltip v-if="calibration && calibration.pixelCalibration" label="Clone to new" class="mr-1"
                           :delay="1000" type="is-dark" position="is-left">
                  <b-button size="is-small" icon-left="content-copy"
                            @click="onCloneCalibration(calibration)"></b-button>
                </b-tooltip>
                <b-tooltip v-if="calibration && calibration.pixelCalibration" label="Edit" class="mr-1"
                           :delay="1000" type="is-dark" position="is-left">
                  <b-button size="is-small" icon-left="pencil"
                            @click="onEditCalibration(calibration)"></b-button>
                </b-tooltip>
                <b-tooltip label="View Details" :delay="1000" type="is-dark" position="is-left">
                  <b-button size="is-small" icon-left="arrow-right"
                            @click="onSelectCalibration(calibration)"></b-button>
                </b-tooltip>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="is-flex is-align-items-center dashed-border mt-6" style="padding: 48px">
      <h4 class="is-size-4 ml-auto mr-auto has-text-grey-light has-text-weight-light">Click on "+" button to add first
        Video Calibration</h4>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getDateTime} from "@/utils/utils";

export default {
  name: "video-calibrations",
  methods: {
    ...mapActions({
      selectCalibration: 'dataProcessingModule/selectCalibration'
    }),
    onSelectCalibration(calibration) {
      if (calibration && calibration.calibration) {
        let calib = JSON.parse(JSON.stringify(calibration))
        calib.isNew = false
        console.log('Selected calib:', calib)
        this.selectCalibration(calib)
      }
    },
    onCloneCalibration(calibration) {
      if (calibration && calibration.calibration) {
        let calib = JSON.parse(JSON.stringify(calibration))
        console.log('On clone calibration:', calib)
        this.$emit('onCloneCalibration', calib)
        /*
                this.selectCalibration(calib)*/
      }
    },
    onEditCalibration(calibration) {
      if (calibration && calibration.calibration) {
        let calib = JSON.parse(JSON.stringify(calibration))
        console.log('On edit calibration:', calib)
        this.$emit('onEditCalibration', calib)
      }
    },
    getDate(dateString) {
      return getDateTime(dateString, this.selectedDevice.site.timezone)
    }
  },
  computed: {
    ...mapGetters({
      calibrations: 'dataProcessingModule/getSelectedVideoCalibrations',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  }
}
</script>

<style scoped>

</style>