<template>
  <div id="chart">
    <apex-chart
        type="bar"
        height="300"
        :options="options"
        :series="ser"
    ></apex-chart>
  </div>
</template>

<script>
export default {
  name: "auto-validation-speed-percentile-chart",
  props: {
    speedChartData: {
      type: Object
    }
  },
  data() {
    return {
      series: [
        {
          name: "Speed Count",
          data: [1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 7, 7, 7, 7, 7, 9, 9, 9, 9, 9, 11, 11, 11, 11, 11, 8, 8, 8, 8, 8, 6, 6, 6, 6, 6, 2, 2, 2, 2, 2, 1, 1, 1, 1, 1],
        },
      ],
      chartOptions: {
        chart: {
          height: 300,
          type: "bar",
          fontFamily: 'Barlow',
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1,
          colors: ['#7568EC']
        },
        annotations: {
          xaxis: [
            {
              x: 8,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {color: "#fff", background: "#00E396"},
                text: "35th Percentile",
              },
            },
            {
              x: 16,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {color: "#fff", background: "#00E396"},
                text: "75th Percentile",
              },
            },
          ],
        },
        plotOptions: {
          bar: {
            columnWidth: "100%",
          },
        },
        xaxis: {
          //type: "numeric",
          tickAmount: 3,
          categories: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44
          ],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            formatter: function (val) {
              let value = Math.floor(val / 5) * 5;
              if (val % 5 === 0) {
                return value;
              } else {
                return value + "-" + (value + 5);
              }
            }, // Do not convert to UTC
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: "Speed Bins Distribution",
          style: {
            color: "#444",
          },
        },
      }
    }
  },
  computed: {
    ser() {
      let series = []
      if (this.speedChartData) {
        if (this.speedChartData.total && this.speedChartData.total.binCounts) {

          let data = []
          this.speedChartData.total.binCounts.forEach(count => {
            data.push(count)
            data.push(count)
            data.push(count)
            data.push(count)
            data.push(count)
          })
          let ser = {
            name: 'Speed Count',
            data: data
          }
          series.push(ser)
        }
      }


      console.log('Series: ', series)
      return series
    },
    options() {
      let categories = []
      let annotationsPercentile = []
      if (this.speedChartData) {
        if (this.speedChartData.total && this.speedChartData.total.bins) {
          let cats = this.speedChartData.total.bins
          for (let i = 0; i < cats.length - 1; i++) {
            categories.push(cats[i])
            categories.push(cats[i] + 1)
            categories.push(cats[i] + 2)
            categories.push(cats[i] + 3)
            categories.push(cats[i] + 4)
          }
        }

        if (this.speedChartData.total && this.speedChartData.total.percentiles) {
          let percentiles = this.speedChartData.total.percentiles
          //let percentiles = {'75th':19, '65th':22}

          Object.keys(percentiles).forEach(key => {
            let perc = {
              x: Math.round(percentiles[key]),
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {color: "#fff", background: "#00E396"},
                text: `${key} Percentile`,
              },
            }
            annotationsPercentile.push(perc)
          })
        }
      }

      console.log('Categories: ', categories)


      return {
        chart: {
          height: 300,
          type: "bar",
          fontFamily: 'Barlow',
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1,
          colors: ['#7568EC']
        },
        annotations: {
          xaxis: annotationsPercentile,
        },
        plotOptions: {
          bar: {
            columnWidth: "100%",
          },
        },
        xaxis: {
          //type: "numeric",
          tickAmount: 3,
          categories: categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            formatter: function (val) {
              let value = Math.floor(val / 5) * 5;
              if (val % 5 === 0) {
                return value;
              } else {
                return value + "-" + (value + 5);
              }
            }, // Do not convert to UTC
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: "Speed Bins Distribution",
          style: {
            color: "#444",
          },
        },
      }
    }
  }
}
</script>

<style scoped>

</style>