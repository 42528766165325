import Vue from 'vue'
import VueRouter from 'vue-router'
import {Auth} from 'aws-amplify';
import register from '@/views/register/register.vue'
import forgot from "@/views/forgot/forgot.vue";
import resetPassword from "@/views/reset-password/reset-password.vue";
import forbiddenPage from "@/views/forbidden/forbidden-page.vue";
import notFound from "@/views/not-found/not-found.vue";
import Utils, {Role} from "@/utils/utils";
import settings from "@/views/settings/settings.vue";
import startPage from "@/views/start-page/start-page.vue";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'start-page',
        meta: {
            title: 'Welcome',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: startPage
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            title: 'Register',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: register
    },
    {
        path: '/forgot',
        name: 'forgot',
        meta: {
            title: 'Forgot Password',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: forgot
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            title: 'Reset password',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: resetPassword
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta: {
            title: 'Forbidden',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: forbiddenPage
    },
    {
        path: '*',
        name: 'not-found',
        meta: {
            title: 'Not found',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: notFound
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            product: 'Realite Admin',
            title: 'Settings',
            isSubCategory: false,
            roles: [Role.ADMIN, Role.TRAFFIC_ENG],
            permission: 'template:create',
            topBarTitle: '',
            menuPriority: 1,
            hasMenu: true,
            hasInternalTransition: false
        },
        component: settings
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
/**
 * Auth check if user is logged in
 */
router.beforeEach(async (to, from, next) => {
    //Disable check just for these routes
    console.log('Router next', to)

    if (to.meta.isUnrestricted) {
        //NOT Authorized
        next()
    } else {
        //Authorized
        await Auth.currentAuthenticatedUser().then(async (user) => {
            console.log('Route user: ', user)
            //let role = user.signInUserSession.idToken.payload['custom:role']
            let permNames = []
            let appNames = []
            if (store.getters.getMe === null) {
                await store.dispatch('getMe')
            }
            if (store.getters.getMe && store.getters.getMe.permissions && store.getters.getMe.permissions.length > 0) {
                permNames = store.getters.getMe.permissions.map(perm => perm.name)
                appNames = store.getters.getMe.apps.map(app => app.name)
            }

            //console.log('Perm names', permNames)

            if(appNames.length > 0){
                if(appNames.includes(Utils.appName)){
                    console.log('Accessing app...')
                }else{
                    console.log('Navigation guard: Can\'t access this app')
                    await router.push('/')
                    return
                }
            }

            if (permNames.length > 0) {
                if (permNames.includes(to.meta.permission)) {
                    console.log('Calling next')
                    next()
                } else {
                    console.log('Navigation guard: Can\'t access this page')
                    await router.replace({name: 'forbidden'})
                }
            } else {
                console.log('Navigation guard: Provide user roles')
            }
        }).catch((error) => {
            //NOT Authorized, redirect to log in
            router.push('/')
            console.log('Navigation guard: ', error)
        });
    }
})
export default router
