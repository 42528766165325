<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="false"
        :is-full-page="true"
    ></b-loading>
    <info-modal ref="infoModal"></info-modal>
    <check-materials-modal ref="checkMaterialsModal" @onProceed="onProceed"></check-materials-modal>
    <div style="background: #eeeeee;">
      <transition name="fade" mode="out-in" v-if="selectedProcessingProject === null">
        <search-box-processing ref="searchBoxProcessing" style="margin-top: 150px"
                               @onToggleSelectedProject="onToggleSelectedProject"></search-box-processing>
      </transition>
      <g-map-data-processing @onMapClicked="onMapClicked" @onMapLoaded="onMapLoaded"></g-map-data-processing>

      <div v-if="selectedProcessingProject" class="pb-6">
        <div>
          <div class="is-flex has-background-white title-box p-2">
            <div class="is-size-4 ml-5 mr-5 pt-1 pb-1 has-text-weight-bold">{{ selectedProcessingProject.project.name }}
            </div>
            <b-button class="card-header-icon ml-auto mt-auto mb-auto mr-2" aria-label="more options"
                      @click="closeProjectOverview">
              <b-icon icon="close"></b-icon>
            </b-button>
          </div>
          <div class="is-flex is-flex-direction-column p-5" style="padding-bottom: 200px !important;">
            <div class="is-size-6 mb-3 mt-2">PROJECT TASKS</div>
            <data-processing-card :progress="progress"
                                  :progress-step-one="progressStepOne"
                                  v-for="dt in selectedProcessingProject.devices" :key="dt._id" :device-data="dt"
                                  @onProcessingClick="onProcessingClick" @onAiClick="onAiClick"
                                  @onRunTasksClick="onRunTasksClick"
                                  @onAddTasksClick="onAddTasksClick" @onRunInitialValidation="onRunInitialValidation"
                                  @onShowInitialResults="onShowInitialResults" @onShowFullResults="onShowFullResults"
                                  @onRunFullProcessing="onRunFullProcessing"
                                  @onRunPedestrianProcessing="onRunPedestrianProcessing"
                                  @onResetStep="onResetStep"
            ></data-processing-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBoxProcessing
  from "@/modules/data-processing-module/components/search-box-processing/search-box-processing.vue";
import {mapActions, mapGetters} from "vuex";
import GMapDataProcessing
  from "@/modules/data-processing-module/components/g-map-data-processing/g-map-data-processing.vue";
import DataProcessingCard
  from "@/modules/data-processing-module/components/data-processing-card/data-processing-card.vue";
import InfoModal from "@/modules/data-processing-module/components/info-modal/info-modal.vue";
import CheckMaterialsModal
  from "@/modules/data-processing-module/components/check-materials-modal/check-materials-modal.vue";
import {getVideoTimeFromLocal, getVideoTimeFromLocalToIsoString} from "@/utils/utils";

export default {
  name: "data-processing-page",
  components: {CheckMaterialsModal, InfoModal, GMapDataProcessing, SearchBoxProcessing, DataProcessingCard},
  data() {
    return {
      isLoading: false,
      timer: null,
      count: 0,
      refreshSeconds: 15,
      refreshSecondStepOne: 350,
      countSecondsStepOne: 0,
      progress: 0,
      progressStepOne: 0,
      refreshingData: false,
      refreshingStepOneData: false,

    }
  },
  mounted() {
    console.log('O Mounted')
    this.selectCalibration(null)
    this.setSelectedProcessingDevice(null)

    this.timer = setInterval(() => {
      if (!this.refreshingData) {
        this.count++
        this.progress = this.count / this.refreshSeconds * 100
        if (this.count === this.refreshSeconds) {
          this.count = 0
          this.progress = this.count / this.refreshSeconds * 100
          this.refreshData()
        }
      }
      if(!this.refreshingStepOneData){
        this.countSecondsStepOne++
        this.progressStepOne = this.countSecondsStepOne / this.refreshSecondStepOne * 100
        if(this.countSecondsStepOne === this.refreshSecondStepOne){
          this.countSecondsStepOne = 0
          this.progressStepOne = this.countSecondsStepOne / this.refreshSecondStepOne * 100
          this.refreshStepOneData()
        }
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.count = 0
  },
  methods: {
    ...mapActions({
      setSelectedProcessingProject: 'dataProcessingModule/setSelectedProcessingProject',
      selectCalibration: 'dataProcessingModule/selectCalibration',
      setSelectedProcessingDevice: 'dataProcessingModule/setSelectedProcessingDevice',
      createTasks: 'dataProcessingModule/createTasks',
      pushTasksToSqs: 'dataProcessingModule/pushTasksToSqs',
      runInitialValidation: 'dataProcessingModule/runInitialValidation',
      getInitialValidation: 'dataProcessingModule/getInitialValidation',
      checkVideoPathTasks: 'dataProcessingModule/checkVideoPathTasks',
      runFullValidation: 'dataProcessingModule/runFullValidation',
      runPedestrianProcessing: 'dataProcessingModule/runPedestrianProcessing',
      resetProcessingStep: 'dataProcessingModule/resetProcessingStep',
      setRefreshingDevices: 'dataProcessingModule/setRefreshingDevices',
      setVideoPathRefreshingDevices: 'dataProcessingModule/setVideoPathRefreshingDevices',
    }),
    async onToggleSelectedProject(project) {
      console.log('Selected project: toggle', project)
      if (project === null) {
        await this.setSelectedProcessingProject(null)
      } else {
        this.isLoading = true
        await this.setSelectedProcessingProject(project)
        this.isLoading = false
      }
    },
    refreshData() {
      console.log('On refresh data')
      this.refreshingData = true
      let refreshDataDevices = this.getRefreshingDevices
      console.log('Refreshing 2-4 devices: ', refreshDataDevices)
      this.setRefreshingDevices(refreshDataDevices.deviceRefresh)

      setTimeout(() => {
        this.refreshingData = false
      }, 5000)

      if (refreshDataDevices.doRefresh) {
        console.log('Run Refresh', refreshDataDevices)
        this.getInitialValidation(this.selectedProcessingProject.project)
      }

    },
    refreshStepOneData() {
      console.log('On refresh step one data')
      this.refreshingStepOneData = true
      let refreshVehiclePathDevices = this.getRefreshVehiclePathDevices
      console.log('Refreshing path devices: ', refreshVehiclePathDevices)
      this.setVideoPathRefreshingDevices(refreshVehiclePathDevices.deviceRefresh)

      setTimeout(() => {
        this.refreshingStepOneData = false
      }, 5000)

      if(refreshVehiclePathDevices.doRefresh){
        this.checkVideoPathTasks(this.selectedProcessingProject.project)
      }
    },
    async onAddTasksClick(deviceData) {

      this.isLoading = true

      let payload = {
        dataDeviceId: deviceData._id,
        saveTasks: false
      }

      let response = await this.createTasks(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        //this.show(false, false)
        console.log('Create tasks response: ', response)
        if (response.summaryCharts) {
          let chartsData = JSON.parse(JSON.stringify(response.summaryCharts))

          /*          let chartsData = [
                      {
                        chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-20",
                        x: ["2024-09-20T00:00:00+10:00",
                          "2024-09-20T17:15:00+10:00",
                          "2024-09-20T17:00:00+10:00",
                          "2024-09-20T16:45:00+10:00",
                          "2024-09-20T16:30:00+10:00",
                          "2024-09-20T16:15:00+10:00"],
                        y: [15, 15, 14, 15, 12, 15]
                      },
                      {
                        chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-21",
                        x: ["2024-09-21T00:00:00+10:00",
                          "2024-09-21T17:15:00+10:00",
                          "2024-09-21T17:00:00+10:00",
                          "2024-09-21T16:45:00+10:00",
                          "2024-09-21T16:30:00+10:00",
                          "2024-09-21T16:15:00+10:00"],
                        y: [15, 13, 15, 11, 15, 15]
                      },
                      {
                        chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-22",
                        x: ["2024-09-22T00:00:00+10:00",
                          "2024-09-22T17:15:00+10:00",
                          "2024-09-22T17:00:00+10:00",
                          "2024-09-22T16:45:00+10:00",
                          "2024-09-22T16:30:00+10:00",
                          "2024-09-22T16:15:00+10:00"],
                        y: [15, 13, 15, 11, 15, 15]
                      },
                      {
                        chartName: "PR-24038 - SPK - U-Turn Analysis - CCTV 1 | Camera 1 | 2024-09-23",
                        x: ["2024-09-23T00:00:00+10:00",
                          "2024-09-23T17:15:00+10:00",
                          "2024-09-23T17:00:00+10:00",
                          "2024-09-23T16:45:00+10:00",
                          "2024-09-23T16:30:00+10:00",
                          "2024-09-23T16:15:00+10:00"],
                        y: [9, 13, 11, 15, 14, 15]
                      }
                    ]*/

          chartsData.forEach(data => {
            data.x = data.x.map(x => getVideoTimeFromLocalToIsoString(x))
          })

          let data = {
            _id: deviceData._id,
            siteName: deviceData.site.name,
            name: deviceData.name,
            chartsData: chartsData
          }
          this.$refs.checkMaterialsModal.show(true, data)
        }
      }

      this.isLoading = false
    },
    async onProceed(dt) {
      let data = JSON.parse(JSON.stringify(dt))
      this.$refs.checkMaterialsModal.show(false, null)
      console.log('On proceed data: ', data)


      this.isLoading = true

      let payload = {
        dataDeviceId: dt._id,
        saveTasks: true
      }

      let response = await this.createTasks(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Tasks successfully created', 'is-success')
        console.log('Create tasks response 1: ', response)
      }

      this.isLoading = false

    },
    async onRunTasksClick(dataDevice) {
      this.isLoading = true

      let payload = {
        data:{
          dataDeviceId: dataDevice._id,
          taskType: 'video_path_tracking',
          version: 1
        },
        selectedProject: this.selectedProcessingProject.project
      }

      let response = await this.pushTasksToSqs(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        let message = 'Tasks successfully pushed to processing queue. Info: '
        if (response.pushToSqsMessage && response.pushToSqsMessage.message) {
          message += response.pushToSqsMessage.message
        } else {
          message += 'No Info'
        }
        this.showToast(message, 'is-success')
      }

      this.isLoading = false
    },
    onMapClicked() {
      console.log('On map clicked')
      if (this.$refs.searchBoxQa) {
        this.$refs.searchBoxProcessing.onClickedOutside()
      }
      console.log('Selected Processing Project: ', this.selectedProcessingProject)
    },
    onMapLoaded() {
      console.log('On map loaded')
    },
    closeProjectOverview() {
      this.setSelectedProcessingProject(null)
    },
    openCalibrationPage() {
      this.$router.push('/video-calibration')
    },
    onProcessingClick(data) {
      this.$refs.infoModal.show(true, data, 'Processing Period/s', 'periods')
    },
    onAiClick(data) {
      this.$refs.infoModal.show(true, data, 'AI Configuration', 'ai')
    },
    async onRunInitialValidation(dataDevice) {
      console.log('Run initial validation: ', dataDevice)
      this.isLoading = true
      //reset timer count
      this.count = 0
      let payload = {
        dataDeviceId: dataDevice._id,
        selectedProject: this.selectedProcessingProject.project
      }
      console.log('Payload: ', payload)

      let response = await this.runInitialValidation(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Initial validation successfully started', 'is-success')
      }

      this.isLoading = false
    },
    async onRunFullProcessing(dataDevice) {
      console.log('On run full processing: ', dataDevice)
      this.isLoading = true
      //reset timer count
      this.count = 0
      let payload = {
        dataDeviceId: dataDevice._id,
        selectedProject: this.selectedProcessingProject.project
      }
      console.log('Payload: ', payload)

      let response = await this.runFullValidation(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Full Data Processing successfully started', 'is-success')
      }

      this.isLoading = false
    },
    async onRunPedestrianProcessing(dataDevice) {
      console.log('Run pedestrian processing: ', dataDevice)
      this.isLoading = true
      //reset timer count
      this.count = 0
      let payload = {
        dataDeviceId: dataDevice._id,
        selectedProject: this.selectedProcessingProject.project
      }
      console.log('Payload: ', payload)

      let response = await this.runPedestrianProcessing(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
        this.isLoading = false
      } else {
        this.showToast('Pedestrian Processing successfully started', 'is-success')
        this.isLoading = false
      }
    },
    onShowInitialResults(dataDevice) {
      console.log('On Show initial results:', dataDevice)
      this.setSelectedProcessingDevice(dataDevice)


      const params = {
        projectId: this.selectedProcessingProject.project._id,
        dataDeviceId: dataDevice._id,
        dataType: 'initial'
      }

      this.$router.push({name: 'auto-validation-review', params: params})

    },
    onShowFullResults(dataDevice) {
      console.log('On Show initial results:', dataDevice)
      this.setSelectedProcessingDevice(dataDevice)


      const params = {
        projectId: this.selectedProcessingProject.project._id,
        dataDeviceId: dataDevice._id,
      }

      this.$router.push({name: 'qa', params: params})
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    async onResetStep(data){
      console.log('Reset step: ', data)
      this.isLoading = true
      //reset timer count
      this.count = 0
      let response = await this.resetProcessingStep(data)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
        this.isLoading = false
      } else {
        this.showToast('Reset Success', 'is-success')
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedProcessingProject: 'dataProcessingModule/getSelectedProcessingProject',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
    getRefreshingDevices() {
      let refreshData = {doRefresh: false, deviceRefresh: []}
      if (this.selectedProcessingProject && this.selectedProcessingProject.devices && this.selectedProcessingProject.devices.length > 0) {
        for (let i = 0; i < this.selectedProcessingProject.devices.length; i++) {
          let refresh = {id: this.selectedProcessingProject.devices[i]._id, refresh: false}
          let tasks

          if (this.selectedProcessingProject.devices[i].tasks) {
            tasks = this.selectedProcessingProject.devices[i].tasks
          }

          let initialTasks
          let initialCharts = null
          if (this.selectedProcessingProject.devices[i].initialValidationData && this.selectedProcessingProject.devices[i].initialValidationData.tasks) {
            initialTasks = this.selectedProcessingProject.devices[i].initialValidationData.tasks
          }
          if (this.selectedProcessingProject.devices[i].initialValidationData && this.selectedProcessingProject.devices[i].initialValidationData.validationChart) {
            initialCharts = this.selectedProcessingProject.devices[i].initialValidationData.validationChart
          }

          let pedTasks
          if (this.selectedProcessingProject.devices[i].initialValidationData && this.selectedProcessingProject.devices[i].initialValidationData.pedTasks) {
            pedTasks = this.selectedProcessingProject.devices[i].initialValidationData.pedTasks
          }

          let fullTasks
          let fullCharts = null
          if (this.selectedProcessingProject.devices[i].initialValidationData && this.selectedProcessingProject.devices[i].initialValidationData.fullTasks) {
            fullTasks = this.selectedProcessingProject.devices[i].initialValidationData.fullTasks
          }
          if (this.selectedProcessingProject.devices[i].initialValidationData && this.selectedProcessingProject.devices[i].initialValidationData.validationChart) {
            fullCharts = this.selectedProcessingProject.devices[i].initialValidationData.fullChart
          }
          //console.log('All Full Tasks', fullTasks)

          let allTasks = [initialTasks, tasks]
          console.log('Tasks', tasks)
          console.log('Initial tasks', initialTasks)

          if (tasks && tasks.status === 'COMPLETED') {
            if(initialTasks && initialTasks.status === 'COMPLETED' && initialCharts !== null && initialCharts.version === initialTasks.version){
              //continue
            } else if (initialTasks && ((initialTasks.status !== 'COMPLETED' && initialTasks.status !== 'FAILED') || initialCharts === null || initialCharts.version !== initialTasks.version)) {
              refresh.refresh = true
              console.log('1', this.selectedProcessingProject.devices[i])
              refreshData.doRefresh = true
            }
          }

          //console.log('All ped Tasks', pedTasks)

          if (pedTasks && (pedTasks.status !== 'COMPLETED' && pedTasks.status !== 'FAILED')) {
            refresh.refresh = true
            refreshData.doRefresh = true
            console.log('2')
          }

          if (fullTasks && ((fullTasks.status !== 'COMPLETED' && fullTasks.status !== 'FAILED') || fullCharts === null || fullCharts.version !== fullTasks.version)) {
            refresh.refresh = true
            refreshData.doRefresh = true
            console.log('3')
          }

          refreshData.deviceRefresh.push(refresh)
        }
      }
      return refreshData
    },
    getRefreshVehiclePathDevices(){
      let refreshData = {doRefresh: false, deviceRefresh: []}
      if (this.selectedProcessingProject && this.selectedProcessingProject.devices && this.selectedProcessingProject.devices.length > 0) {
        for (let i = 0; i < this.selectedProcessingProject.devices.length; i++) {
          let refresh = {id: this.selectedProcessingProject.devices[i]._id, refresh: false}
          let tasks

          if (this.selectedProcessingProject.devices[i].tasks) {
            tasks = this.selectedProcessingProject.devices[i].tasks
          }
          if (tasks && tasks.status !== 'COMPLETED' && tasks.status !== 'FAILED' && tasks !== 'CREATED') {
              refresh.refresh = true
              console.log('1', this.selectedProcessingProject.devices[i])
              refreshData.doRefresh = true
          }
          refreshData.deviceRefresh.push(refresh)
        }
      }
      return refreshData
    }
  }
}
</script>

<style scoped>
.title-box {
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 0;
  box-shadow: 0 0.125em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: hsl(0deg, 0%, 29%);
  display: block;
  padding: 1.25rem;
}
</style>