<template>
<div>
  <apex-chart type="bar" height="300" :options="chartOptions" :series="series"></apex-chart>
</div>
</template>

<script>
export default {
  name: "auto-validation-speed-chart",
  props:{
    speedChartData:{
      type: Object
    }
  },
  mounted() {
    console.log('Speed chart data: ', this.speedChartData)
  },
  data(){
    return {
/*      series: [{
        name: 'Northbound',
        data: [0, 55, 57, 56, 61, 58, 63, 60, 66, 58, 63, 60, 66]
      }, {
        name: 'Southbound',
        data: [0, 85, 101, 98, 87, 105, 91, 114, 94,87, 105, 91, 114]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 300
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'],
        },
        yaxis: {
        },
        fill: {
          opacity: 1,
          colors: ['#7568EC', '#E5AD12']
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },*/
    }
  },
  computed:{
    series(){
      let series = []
      if(this.speedChartData){

        let names = Object.keys(this.speedChartData)
        console.log('Names', names)
        if(names && names.length > 0){
          names.forEach(name => {
            let ser = {
              name: name,
              data: this.speedChartData[name].binCounts
            }
            series.push(ser)
          })
        }

/*        series = [{
          name: 'Northbound',
          data: [0, 55, 57, 56, 61, 58, 63, 60, 66, 58, 63, 60, 66]
        }, {
          name: 'Southbound',
          data: [0, 85, 101, 98, 87, 105, 91, 114, 94,87, 105, 91, 114]
        }]*/
      }
      return series
    },
    chartOptions(){
      let categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60']
      if(this.speedChartData) {
        let cats = []
        let names = Object.keys(this.speedChartData)
        console.log('Names', names)
        if (names && names.length > 0) {
          categories = []
          cats = this.speedChartData[names[0]].bins
          for (let i = 0; i < cats.length - 1; i++) {
            categories.push(`${cats[i]} - ${cats[i + 1]}`)
          }
        }
      }

      return {
        chart: {
          type: 'bar',
          height: 300
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
        },
        fill: {
          opacity: 1,
          //colors: ['#7568EC', '#E5AD12']
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>