<template>
  <div class="ml-0 mr-0 mt-0 box">
    <apex-chart height="400" type="bar" :options="chartOptions" :series="series" ref="apexChart"></apex-chart>
  </div>
</template>

<script>
import {mergeDeduplicate} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "final-vehicle-volume-chart",
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 400,
          fontFamily: 'Barlow'
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Vehicles Distribution'
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          min: new Date('2024-09-06T00:00:00.000Z').getTime(),
          max: new Date('2024-09-08T00:00:00.000Z').getTime(),
          categories: [],
          labels: {
            datetimeUTC: true,
            /*            formatter: function (value) {
                          return new Date(value).
                        }*/
            /*            formatter: function(value, timestamp, opts) {
                          return timestamp
                        }*/
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          },
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      },
    }
  },
  mounted() {
    console.log('Today: ', new Date().toISOString())
    console.log('Selected device: ',this.selectedDevice)
    this.setChartData()
  },
  methods:{
    setChartData(){
      console.log('HERE DATA', this.chartsData)

      let series = []
      let categories = []

      if(this.chartsData && this.chartsData.data){
        let vehVolumeData = this.chartsData.data
        if(vehVolumeData.length > 0){
          let arrays = vehVolumeData.map(volData => volData.x)
          console.log('Arrays: ', arrays)

          categories = mergeDeduplicate(arrays)
          console.log('Unique: ', categories)

          vehVolumeData.forEach(volData => {
            let ser = {
              name: volData.name,
              data:[]
            }

            categories.forEach(date => {
              let index = volData.x.indexOf(date)
              if(index !== -1){
                ser.data.push(volData.y[index])
              }else{
                ser.data.push(0)
              }
            })

            series.push(ser)
          })
        }
      }

      this.series = series

      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 400,
          fontFamily: 'Barlow',
          animations: {
            enabled: false,
          }
        },
        markers:{
          size:0
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Vehicles Distribution'
        },
        stroke: {
          show: true,
          width: 2,
          curve: 'straight',
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            datetimeUTC: true,
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy HH:mm:ss"
          },
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      }
    }
  },
  computed:{
    ...mapGetters({
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
    chartsData(){
      console.log('SEL Device: ', this.selectedDevice)
      if (this.selectedDevice && this.selectedDevice.initialValidationData && this.selectedDevice.initialValidationData.fullChart) {
        return this.selectedDevice.initialValidationData.fullChart.vehVolumeChart
      }

      return null
    }
  },
}
</script>

<style scoped>

</style>