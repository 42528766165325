<template>
  <div class="column">
    <div class="columns">
      <div class="column">
        <div v-if="deviceData.tasks === null" style="height: 48px"
             class="is-flex is-align-items-center dashed-border-small ml-0 mr-0 mt-auto mb-auto">
          <h4 class="is-size-6 ml-auto mr-auto has-text-grey-light has-text-weight-light">{{ getMessage() }}</h4>
        </div>
        <div v-else style="height: 48px" class="is-flex mt-auto mb-auto">
          <b-tooltip label="Stage #1 - Tasks Initialisation" type="is-dark" position="is-left" :delay="1000">
          <div class="clickable-info has-text-centered is-flex mr-1" style="height: 48px; width: 48px; background: rgba(30,43,87,0.4); cursor: default">
            <div class="is-size-6 mt-auto mb-auto ml-auto mr-auto has-text-white">#1</div>
          </div>
          </b-tooltip>
          <hr class="has-background-grey-lighter m-0" style="width: 1px; height: 48px">
          <div class="clickable-info has-text-centered is-flex ml-1" style="height: 48px; width: 80px"
               :style="statusStyle()">
            <div class="is-size-7 mt-auto mb-auto ml-auto mr-auto has-text-white">{{ deviceData.tasks.status }}</div>
          </div>
          <div class="clickable-info ml-2 is-flex pt-2 pl-4" style="flex-grow: 1">
            <div class="m-0" style="flex: 1">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">
                {{ deviceData.tasks.status === 'CREATED' ? 'CREATED TASKS' : 'QUEUED' }}
              </div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">
                {{ deviceData.tasks.status === 'CREATED' ? deviceData.tasks.created : deviceData.tasks.queued }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">IN PROGRESS</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.inProgress
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">REPROCESSING</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.reprocessing
                }}
              </div>
            </div>
            <div style="flex: 0.5" class="is-flex" v-if="deviceData.tasks.status !== 'CREATED'">
              <hr class="mt-0 mb-1 ml-auto mr-auto has-background-grey-lighter" style="width: 1px; height: 30px">
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">REPROC</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.reprocessed
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">FAILED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.failed
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="deviceData.tasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">COMPLETED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  deviceData.tasks.completed
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-flex" style="max-width: 96px">
        <view-guard :permission="'data-processing:create-tasks'" class="ml-auto mr-0 mt-auto mb-auto">
          <b-tooltip v-if="refreshing"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Please Wait..." :delay="1000" type="is-dark" position="is-left">
            <div v-if="refreshing" class="my-loader mt-1 mb-1"></div>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'FAILED' && allConditions() && !refreshing"
                     class="ml-0 mr-0 mt-auto mb-auto" :label="checkPermission() === false ? 'Please contact System Admin' : 'Reset Processing'" :delay="1000" type="is-dark" position="is-left">
            <b-button type="is-primary" @click="onResetStep" :disabled="checkPermission() === false" style="background-color: rgba(30,43,87,0.4)">Reset</b-button>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks === null && allConditions() && !refreshing" label="Create Tasks" :delay="1000" position="is-left"
                     type="is-dark">
            <b-icon icon="plus-circle" class="clickable" style="color: rgba(30,43,87,0.4)"
                    size="is-medium" @click.native="onAddClick"></b-icon>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'CREATED' && allConditions() && !refreshing"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Run Tasks" :delay="1000" type="is-dark" position="is-left">
            <b-icon
                icon="play-circle"
                class="clickable" size="is-medium" style="color: rgba(30,43,87,0.4)"
                @click.native="onRunClick"></b-icon>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'COMPLETED' && allConditions() && !refreshing"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Run Initial Validation" :delay="1000" type="is-dark" position="is-left">
            <b-icon
                icon="play-circle"
                class="clickable" size="is-medium" style="color: rgba(30,43,87,0.6)"
                @click.native="onRunInitialValidation"></b-icon>
          </b-tooltip>
          <b-tooltip v-else-if="deviceData.tasks && deviceData.tasks.status === 'RUNNING' && !refreshing"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Please Wait..." :delay="1000" type="is-dark" position="is-left">
            <b-icon
                icon="progress-clock"
                class="clickable" size="is-medium"></b-icon>

            <b-progress size="is-small" :value="progressValue"></b-progress>
          </b-tooltip>
        </view-guard>
      </div>
    </div>
  </div>
</template>

<script>
import ViewGuard from "@/components/view-guard/view-guard.vue";
import {isAllowed} from "@/utils/utils";

export default {
  name: "tasks-info",
  components: {ViewGuard},
  props: {
    deviceData: {
      type: Object
    },
    refreshing: {
      type: Boolean
    }
  },
  data(){
    return {
      progressValue:40,
    }
  },
  methods: {
    setProgress(progress){
      this.progressValue = progress
    },
    onResetStep(){
      this.$emit('onResetStep', 'video-path-tracking')
    },
    checkPermission() {
      return isAllowed(this.$store.getters.getMe, 'data-processing:reset-step')
    },
    getMessage() {
      if (this.allConditions()) {
        return 'This device has no tasks'
      } else {
        return 'Conditions missing'
      }
    },
    statusStyle() {
      if (this.deviceData.tasks && this.deviceData.tasks.status) {
        if (this.deviceData.tasks.status === 'CREATED') {
          return {background: '#8C84FF'}
        } else if (this.deviceData.tasks.status === 'RUNNING') {
          return {background: '#D8BA13'}
        } else if (this.deviceData.tasks.status === 'COMPLETED') {
          return {background: '#1CAB80'}
        }else if (this.deviceData.tasks.status === 'FAILED') {
          return {background: '#e02c2c'}
        }
      }
      return {background: '#000000'}
    },
    allConditions() {
      return this.isVideoCalibrationExists() && this.deviceData.processingPeriods && this.deviceData.processingPeriods.length > 0 && this.deviceData.trackingConfig
    },
    onAddClick() {
      console.log('On Add task clicked')
      this.$emit('onAddTasksClick', this.deviceData)
    },
    onRunClick() {
      console.log('On Run task clicked')
      this.$emit('onRunTasksClick', this.deviceData)
    },
    onRunInitialValidation() {
      this.$emit('onRunInitialValidation', this.deviceData)
    },
    isVideoCalibrationExists() {
      if (this.deviceData.videoCalibrations && this.deviceData.videoCalibrations.length > 0) {
        if (this.deviceData.videoCalibrations.filter(calibration => calibration.status === 'active').length > 0) {
          return true
        }
      }
      return false
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dashed-border-small {
  padding: 8px 8px;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.clickable-info {
  background: #f4f4f4;
  border-radius: 0.3rem;
}

/*.clickable-info:hover {
  cursor: pointer;
}*/

.clickable:hover {
  cursor: pointer;
}

.dashed-border-small {
  padding: 8px 8px;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.my-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #1cab80;
  border-bottom: 4px solid #1cab80;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>