import { render, staticRenderFns } from "./project-sites.vue?vue&type=template&id=588f1fb0&scoped=true"
import script from "./project-sites.vue?vue&type=script&lang=js"
export * from "./project-sites.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588f1fb0",
  null
  
)

export default component.exports