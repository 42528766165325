import Vue from "vue";

export default {
    setQueryData: async ({commit}, queryData) => {
        commit('setQueryData', queryData)
    },
    setRefreshingDevices: async ({commit}, refreshingDevices) => {
        commit('setRefreshingDevices', refreshingDevices)
    },
    setVideoPathRefreshingDevices: async ({commit}, refreshingDevices) => {
        commit('setVideoPathRefreshingDevices', refreshingDevices)
    },
    setSelectedProcessingProject: async ({commit, dispatch}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'get',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/data-processing-report',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {projectId: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then(async (response) => {
                    console.log('Get processing project response: ', response)
                    if (response.status === 200) {
                        commit('setSelectedProcessingProject', response.data)
                        await dispatch('getInitialValidation', selectedProject)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedProcessingProject', null)
            commit('setSelectedVideoCalibration', null)
            commit('setSelectedProcessingDevice', null)
        }
    },
    checkVideoPathTasks: async ({commit}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'post',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/check-video-path-tasks',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {projectId: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Check video path tasks: ', response)
                    if (response.status === 200) {
                        commit('replaceVideoPathTasks', response.data)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedProcessingProject', null)
            commit('setSelectedVideoCalibration', null)
            commit('setSelectedProcessingDevice', null)
        }
    },
    getInitialValidation: async ({commit}, selectedProject) => {
        if (selectedProject) {
            let config = {
                method: 'post',
                url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/check-auto-validation',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {projectId: selectedProject._id},
            };
            return await Vue.prototype.$http(config)
                .then((response) => {
                    console.log('Get initial validation response: ', response)
                    if (response.status === 200) {
                        commit('mergeInitialValidationData', response.data)
                    }
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            commit('setSelectedProcessingProject', null)
            commit('setSelectedVideoCalibration', null)
            commit('setSelectedProcessingDevice', null)
        }
    },
    runInitialValidation: async ({commit, dispatch}, data) => {

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/auto-validation',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {dataDeviceId: data.dataDeviceId},
        };

        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get run initial validation response: ', response)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else if (response.data.type && response.data.type === 'error') {
                    return Error(response.data.message)
                }else if (response.status === 200) {
                    commit('setRefreshForDataDevice', {id: data.dataDeviceId, refresh: true})
                    setTimeout(()=>{
                        dispatch('getInitialValidation', data.selectedProject)
                    }, 3000)

                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    runFullValidation: async ({commit, dispatch}, data) => {

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/full-processing',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {dataDeviceId: data.dataDeviceId},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get run full processing response: ', response)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else if (response.data.type && response.data.type === 'error') {
                    return Error(response.data.message)
                }else if (response.status === 200) {
                    commit('setRefreshForDataDevice', {id: data.dataDeviceId, refresh: true})
                    //await new Promise(resolve => setTimeout(resolve, 3000));
                    setTimeout(()=>{
                        dispatch('getInitialValidation', data.selectedProject)
                    }, 10000)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    runPedestrianProcessing: async ({commit, dispatch}, data) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/ped-processing',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {dataDeviceId: data.dataDeviceId},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get run pedestrian processing response: ', response)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else if (response.data.type && response.data.type === 'error') {
                    return Error(response.data.message)
                }else if (response.status === 200) {
                    commit('setRefreshForDataDevice', {id: data.dataDeviceId, refresh: true})
                    //await new Promise(resolve => setTimeout(resolve, 3000));
                    setTimeout(()=>{
                        dispatch('getInitialValidation', data.selectedProject)
                    }, 3000)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    setSelectedProcessingDevice: async ({commit}, processingDevice) => {
        if (processingDevice) {
            commit('setSelectedProcessingDevice', processingDevice)
        } else {
            commit('setSelectedProcessingDevice', null)
        }
    },
    setSelectedVideoCalibrations: async ({commit}, selectedCalibration) => {
        if (selectedCalibration) {
            commit('setSelectedVideoCalibration', selectedCalibration)
        } else {
            commit('setSelectedVideoCalibration', null)
        }
    },
    selectCalibration: async ({commit}, calibration) => {
        if (calibration) {
            commit('selectCalibration', calibration)
        } else {
            commit('selectCalibration', null)
        }
    },
    saveCalibration: async ({commit}, calibrationPayload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/video-calibration',
            headers: {
                'Content-Type': 'application/json'
            },
            data: calibrationPayload,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Get save video calibration response: ', response)
                if (response.status === 200) {
                    commit('replaceVideoCalibrations', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    editCalibration: async ({commit}, calibrationPayload) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/video-calibration',
            headers: {
                'Content-Type': 'application/json'
            },
            data: calibrationPayload,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Update video calibration response: ', response)
                if (response.status === 200) {
                    commit('replaceVideoCalibrations', response.data)
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    createTasks: async ({commit}, taskPayload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/tasks',
            headers: {
                'Content-Type': 'application/json'
            },
            data: taskPayload,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Create Tasks response: ', response)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else if (response.data.type && response.data.type === 'error') {
                    return Error(response.data.message)
                } else if (response.status === 200) {
                    if (response.data && response.data.dataDeviceId) {
                        commit('updateDeviceTasks', response.data)
                    }
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    pushTasksToSqs: async ({commit, dispatch}, payload) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/tasks/push',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload.data,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Push Tasks response: ', response)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else if (response.data.type && response.data.type === 'error') {
                    return Error(response.data.message)
                } else if (response.status === 200) {
                    if (response.data && response.data.dataDeviceId) {
                        commit('setRefreshForDataDeviceStepOne', {id: payload.data.dataDeviceId, refresh: true})
                        setTimeout(()=>{
                            dispatch('checkVideoPathTasks', payload.selectedProject)
                        }, 10000)
                    }
                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    setDpLegendSelection: async ({commit}, selection) => {
        commit('setDpLegendSelection', selection)
    },
    getVideoUrl: async ({commit}, videoMetaDataId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/video-presigned-url',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {videoMetaDataId: videoMetaDataId},
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Change video response: ', response)
                /*                if (response.status === 200) {
                                    commit('changeSelectedCalibrationVideo', response.data)
                                }*/
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    resetProcessingStep: async ({commit, dispatch}, data) => {

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-data-processing/reset-step',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        };
        return await Vue.prototype.$http(config)
            .then((response) => {
                console.log('Reset Processing Step Response: ', response)
                if (response.data.errorMessage) {
                    return Error(response.data.errorMessage)
                } else if (response.data.type && response.data.type === 'error') {
                    return Error(response.data.message)
                }else if (response.status === 200) {
                    if(data.stepName === 'video-path-tracking'){
                        commit('replaceVideoPathData', response.data)
                    }else{
                        commit('replaceValidationData', response.data)
                    }

                }
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
}

