<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="false"
        :is-full-page="true"
    ></b-loading>
    <div style="background: #eeeeee;padding-bottom: 120px">
      <div class="is-flex m-0 p-0">
        <div class="video-img" style="width: 50%; line-height: 0; position: relative">
          <video id="vid" ref="videoRef"
                 :src="selectedCalibration && selectedCalibration.videoUrl ? selectedCalibration.videoUrl : selectedDevice ? selectedDevice.videoUrl : ''"
                 @loadedmetadata="onVideoMetaDataLoaded" loop=""
                 preload="auto"
                 muted="" style="width: 100%;"></video>
          <div class="canvas-style" ref="canvas">
            <draw-on-video ref="videoDraw" @onDrawFinished="onDrawFinished" @onPlayVideo="onPlayPauseVideo"
                           :is-video-playing="isVideoPlaying" :is-video-loaded="videoLoaded"
                           @onDeleteImage="onDeleteImage" @onColorChanged="onColorChanged"
                           :video-start="getVideoStart()" :video-end="getVideoEnd()" @onVideoChanged="onVideoChanged"
                           :tabIndex="tabIndex"></draw-on-video>
          </div>
        </div>
        <div class="m-0 p-0 video-img" style="width: 50%; border: none">
          <draw-map ref="mapDraw" @onLinesDrawn="onLinesDrawn" :selected-tab="tabIndex"></draw-map>
        </div>
      </div>
      <actions-bar ref="actionsBar" @onSaveData="saveData" @onCloseCalibration="onCloseCalibration"></actions-bar>
      <div v-if="selectedCalibration" class="m-4 mb-6 pb-6 box" style="min-height: 500px;">
        <b-tabs type="is-toggle" v-model="tabIndex" @input="onTabChanged">
          <b-tab-item label="Stage Polygons" icon="vector-polygon-variant">
            <div>
              <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
              <div class="card shadow mb-3" v-for="polygon in videoData.stagePolygons" :key="polygon.id">
                <div class="is-flex p-3 pt-3 columns m-0">
                  <div class="column is-2 m-0 p-0">
                    <b-field label="Polygon Name" label-position="on-border" class="mr-2">
                      <b-input placeholder="Polygon Name" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="polygon.storeData.polyName" @input="onPolyDataChanged"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-4 m-0 p-0">
                    <b-field label="Coordinates" label-position="on-border">
                      <b-input placeholder="Coordinates" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="polygon.ptsString"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-2 m-0 p-0">
                    <div class="m-0 ml-3 p-0">
                      <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">CROSSING
                        DISTANCE
                      </div>
                      <div class="has-text-black has-text-weight-semibold" style="line-height: 1; font-size: 12px">
                        {{ polygon.storeData.crossingDistance ? polygon.storeData.crossingDistance : '-' }}
                      </div>
                    </div>
                  </div>
                  <div class="column is-4 m-0 p-0 is-flex">
                    <b-button icon-left="delete" size="is-small" class="ml-auto" @mouseover="onHover(polygon.id)"
                              @mouseleave="onLeave(polygon.id)" @click="onDelete(polygon.id)"></b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Direction Polygons" icon="vector-polygon-variant">
            <div>
              <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
              <div class="card shadow mb-3" v-for="polygon in videoData.directionPolygons" :key="polygon.id">
                <div class="is-flex p-3 pt-3 columns m-0">
                  <div class="column is-2 m-0 p-0">
                    <b-field label="Polygon Name" label-position="on-border" class="mr-2">
                      <b-input placeholder="Polygon Name" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="polygon.storeData.polyName" @input="onPolyDataChanged"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-4 m-0 p-0">
                    <b-field label="Coordinates" label-position="on-border">
                      <b-input placeholder="Coordinates" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="polygon.ptsString"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6 m-0 p-0 is-flex">
                    <b-button icon-left="delete" size="is-small" class="ml-auto" @mouseover="onHover(polygon.id)"
                              @mouseleave="onLeave(polygon.id)" @click="onDelete(polygon.id)"></b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Dwell Polygons" icon="vector-polygon-variant">
            <div>
              <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
              <div class="card shadow mb-3" v-for="polygon in videoData.dwellPolygons" :key="polygon.id">
                <div class="is-flex p-3 pt-3 columns m-0">
                  <div class="column is-2 m-0 p-0">
                    <b-field label="Ped Direction" label-position="on-border" class="mr-2">
                      <b-input placeholder="Ped direction" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="polygon.storeData.pedDirection" @input="onPolyDataChanged"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-4 m-0 p-0">
                    <b-field label="Coordinates" label-position="on-border">
                      <b-input placeholder="Coordinates" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="polygon.ptsString"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6 m-0 p-0 is-flex">
                    <b-button icon-left="delete" size="is-small" class="ml-auto" @mouseover="onHover(polygon.id)"
                              @mouseleave="onLeave(polygon.id)" @click="onDelete(polygon.id)"></b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Speed Lines" icon="vector-line">
            <div>
              <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
              <div class="card shadow mb-3" v-for="line in videoData.speedLines" :key="line.id">
                <div class="is-flex p-3 pt-3 columns m-0">
                  <div class="column is-6 m-0 p-0">
                    <b-field label="Coordinates" label-position="on-border">
                      <b-input placeholder="Coordinates" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="line.ptsString"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6 m-0 p-0 is-flex">
                    <b-button icon-left="delete" size="is-small" class="ml-auto" @mouseover="onHover(line.id)"
                              :disabled="!selectedCalibration || !selectedCalibration.isNew"
                              @mouseleave="onLeave(line.id)" @click="onDelete(line.id)"></b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Event lines" icon="vector-line">
            <div>
              <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
              <div class="card shadow mb-3" v-for="eventLine in videoData.eventLines" :key="eventLine.id">
                <div class="is-flex p-3 pt-3 columns m-0">
                  <div class="column is-6 m-0 p-0">
                    <b-field label="Coordinates" label-position="on-border">
                      <b-input placeholder="Coordinates" rounded type="text" size="is-small"
                               :disabled="!selectedCalibration || !selectedCalibration.isNew"
                               v-model="eventLine.ptsString"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6 m-0 p-0 is-flex">
                    <b-button icon-left="delete" size="is-small" class="ml-auto" @mouseover="onHover(eventLine.id)"
                              :disabled="!selectedCalibration || !selectedCalibration.isNew"
                              @mouseleave="onLeave(eventLine.id)" @click="onDelete(eventLine.id)"></b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Approaches" icon="road-variant">
            <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
            <div class="card shadow mb-3" v-for="approach in videoData.approaches" :key="approach.id">
              <div class="is-flex p-3 pt-3 columns m-0">
                <div class="column is-3 m-0 p-0">
                  <b-field label="Direction Name" label-position="on-border" class="mr-2">
                    <b-input placeholder="ID" rounded type="text" size="is-small"
                             :disabled="!selectedCalibration || !selectedCalibration.isNew"
                             v-model="approach.key" @input="onApproachKeyChanged(approach.id, $event)"></b-input>
                  </b-field>
                </div>
                <div class="column is-3 m-0 p-0">
                  <b-field label="Approach Name" label-position="on-border" class="mr-2">
                    <b-input placeholder="Approach Name" rounded type="text" size="is-small"
                             :disabled="!selectedCalibration || !selectedCalibration.isNew"
                             v-model="approach.value"></b-input>
                  </b-field>
                </div>
                <div class="column is-6 m-0 p-0 is-flex">
                  <b-button icon-left="delete" size="is-small" class="ml-auto" @mouseover="onHover(approach.id)"
                            :disabled="!selectedCalibration || !selectedCalibration.isNew"
                            @mouseleave="onLeave(approach.id)" @click="onDelete(approach.id)"></b-button>
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Output" icon="code-json">
            <hr class="mt-3 mb-4 has-background-grey-light mr-0" style="height: 1px">
            <div class="is-flex p-3 pt-3 columns m-0">
              <div class="column is-6 m-0 p-0">
                <b-field label="Output" label-position="on-border" class="mr-2">
                  <b-input placeholder="OUTPUT" rounded type="textarea" size="is-small"
                           :disabled="!selectedCalibration || !selectedCalibration.isNew"
                           :value="getOutput()"></b-input>
                </b-field>
              </div>
              <div class="column is-6 m-0 p-0">
                <b-field label="Normalized Output" label-position="on-border" class="mr-2">
                  <b-input placeholder="NORMALIZED OUTPUT" rounded type="textarea" size="is-small"
                           :disabled="!selectedCalibration || !selectedCalibration.isNew"
                           :value="getNormalizedData()"></b-input>
                </b-field>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
      <div v-else class="m-4 pb-6 mt-5" style="min-height: 500px;">
        <video-calibrations @onCloneCalibration="onCloneCalibration"
                            @onEditCalibration="onEditCalibration"></video-calibrations>
      </div>
    </div>
  </div>
</template>
<script>
import DrawMap from "@/modules/data-processing-module/components/draw-map/draw-map.vue";
import DrawOnVideo from "@/modules/data-processing-module/components/draw-canvas/draw-on-video.vue";
import {distance, point} from "@turf/turf";
import index, {mapActions, mapGetters} from "vuex";
import VideoCalibrations from "@/modules/data-processing-module/components/video-calibrations/video-calibrations.vue";
import ActionsBar from "@/modules/data-processing-module/components/actions-bar/actions-bar.vue";
import {formatTimeInTimeZone, getDateTime, getVideoTimeFromLocal, VideoCalibrationCategory} from "@/utils/utils";

export default {
  name: "video-calibration",
  components: {ActionsBar, VideoCalibrations, DrawMap, DrawOnVideo},
  props: {
    projectId: {
      type: String,
      required: true,
      default: '',
    },
    dataDeviceId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      videoSrcWidth: 0,
      videoSrcHeight: 0,
      videoLoaded: false,
      tabIndex: -1,
      videoData: {
        stagePolygons: [],
        directionPolygons: [],
        dwellPolygons: [],
        speedLines: [],
        eventLines: [],
        approaches: [],
        measurements: [],
        geoLines: null,
        crossingDistanceGeoLines: null,
        speedDistances: []
      },
      storeData: null,
      isVideoPlaying: false
    }
  },
  async mounted() {
    if (this.allProjects.length === 0) {
      this.isLoading = true
      await this.fetchAllProjects()
      let index = this.allProjects.findIndex(prj => prj._id === this.projectId)
      if (index !== -1) {
        await this.setSelectedProcessingProject(this.allProjects[index])

        //Find device data
        let deviceIndex = this.selectedProject.devices.findIndex(device => device._id === this.dataDeviceId)
        if (deviceIndex !== -1) {
          await this.setSelectedVideoCalibrations(this.selectedProject.devices[deviceIndex].videoCalibrations)
          await this.setSelectedProcessingDevice(this.selectedProject.devices[deviceIndex])
        }
      }

      this.isLoading = false
    }

    this.scrollToTop()
    this.video = document.getElementById("vid");
    this.timeout = null
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const cr = entry.contentRect;
        console.log(`Element size: ${cr.width}px x ${cr.height}px`);
        let roundedWidth = Math.round(cr.width)
        let roundedHeight = Math.round(cr.height)

        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          if (this.$refs.videoDraw) {
            this.$refs.videoDraw.setSize(roundedWidth, roundedHeight)
            this.$refs.mapDraw.setMapHeight(roundedHeight)
            this.videoSrcWidth = this.video.videoWidth
            this.videoSrcHeight = this.video.videoHeight
            console.log('Width&Height of video', this.videoSrcWidth, this.videoSrcHeight)
            this.$refs.videoDraw.setOriginalVideoDimensions(this.videoSrcWidth, this.videoSrcHeight)
            if (this.videoSrcWidth && this.videoSrcHeight) {
              this.isLoading = false
            }
          }
        }, 300);

      })
    })
    observer.observe(this.$refs.videoRef)

    this.convertAndSetData()
    this.parseData(this.storeData)
    this.$refs.mapDraw.setFeature(this.storeData ? this.storeData.geoLines : null)
    this.$refs.mapDraw.setCrossingDistanceFeature(this.storeData ? this.storeData.crossingDistanceGeoLines : null)
    this.isLoading = true
  },
  beforeDestroy() {

  },
  methods: {
    ...mapActions({
      selectCalibration: 'dataProcessingModule/selectCalibration',
      saveCalibration: 'dataProcessingModule/saveCalibration',
      editCalibration: 'dataProcessingModule/editCalibration',
      fetchAllProjects: 'projectsModule/getAllProjects',
      setSelectedProcessingProject: 'dataProcessingModule/setSelectedProcessingProject',
      setSelectedVideoCalibrations: 'dataProcessingModule/setSelectedVideoCalibrations',
      setSelectedProcessingDevice: 'dataProcessingModule/setSelectedProcessingDevice',
      getVideoUrl: 'dataProcessingModule/getVideoUrl',
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async onVideoChanged(videoMetaData) {
      this.videoLoaded = false
      this.isLoading = true
      let response = await this.getVideoUrl(videoMetaData._id)
      if (response.errorMessage) {
        this.showToast(response.errorMessage, 'is-danger')
      } else {
        //this.showToast('Video Calibration successfully created', 'is-success')
        let selected = JSON.parse(JSON.stringify(this.selectedCalibration))
        selected.videoMetaDataId = response.videoMetaDataId
        selected.localCaptureStart = response.localCaptureStart
        selected.localCaptureEnd = response.localCaptureEnd
        selected.videoUrl = response.videoUrl
        await this.selectCalibration(selected)
        this.$refs.videoRef.src = selected.videoUrl
      }
    },
    onPlayPauseVideo() {
      if (this.isVideoPlaying) {
        this.isVideoPlaying = false
        this.$refs.videoRef.pause()
      } else {
        this.isVideoPlaying = true
        this.$refs.videoRef.play()
      }
    },
    onCloseCalibration() {
      this.$refs.mapDraw.onClose()
    },
    getVideoStart() {
      if (this.selectedCalibration) {
        if (this.selectedCalibration.localCaptureStart) {
          return getDateTime(this.selectedCalibration.localCaptureStart, this.selectedDevice.site.timezone)
        }
        return '--:--'
      } else if (this.selectedDevice) {
        if (this.selectedDevice.localCaptureStart) {
          return getDateTime(this.selectedDevice.localCaptureStart, this.selectedDevice.site.timezone)
        }
      }

      return '--:--'
    },
    getVideoEnd() {
      if (this.selectedCalibration) {
        if (this.selectedCalibration.localCaptureEnd) {
          return getDateTime(this.selectedCalibration.localCaptureEnd, this.selectedDevice.site.timezone)
        }

        return '--:--'
      } else if (this.selectedDevice) {
        if (this.selectedDevice.localCaptureEnd) {
          return getDateTime(this.selectedDevice.localCaptureEnd, this.selectedDevice.site.timezone)
        }
      }

      return '--:--'
    },
    convertAndSetData() {
      if (this.selectedCalibration && this.selectedCalibration.pixelCalibration) {
        let data = JSON.parse(JSON.stringify(this.selectedCalibration.pixelCalibration))
        if (data && data.approaches) {
          let approachesList = []
          Object.keys(data.approaches).forEach(key => {
            let item = {}
            item[key] = data.approaches[key]
            approachesList.push(item)
          })
          data.approaches = approachesList
        }

        this.storeData = JSON.parse(JSON.stringify(data))
        console.log('Convert and set data: ', this.storeData)
      } else {
        this.storeData = null
      }
    },
    getOutput() {
      console.log('Get output store: ', this.storeData)
      console.log('Get output video: ', this.videoData)
      let store = JSON.parse(JSON.stringify(this.storeData))
      if (store && store.approaches) {
        let dict = {};
        store.approaches.forEach(approach => {
          let key = Object.keys(approach)[0]
          dict[key] = approach[key]
        })
        store.approaches = dict
      }
      return JSON.stringify(store)
    },
    getNormalizedData() {
      let store = JSON.parse(JSON.stringify(this.storeData))

      if (store && store.approaches) {
        let dict = {};
        store.approaches.forEach(approach => {
          let key = Object.keys(approach)[0]
          dict[key] = approach[key]
        })
        store.approaches = dict
      }

      return JSON.stringify(this.normalize(store))
    },
    normalize(storeData) {
      if (this.videoSrcWidth && this.videoSrcHeight && storeData) {
        let normalizedData = JSON.parse(JSON.stringify(storeData))
        if (normalizedData.stagePolygons && normalizedData.stagePolygons.length > 0) {
          normalizedData.stagePolygons.forEach(polygon => {
            if (polygon.pts) {
              polygon.pts.forEach(pt => {
                pt[0] = parseFloat((pt[0] / this.videoSrcWidth).toFixed(3))
                pt[1] = parseFloat((pt[1] / this.videoSrcHeight).toFixed(3))
              })
            }

            console.log('POLY: ', polygon)

            if (polygon.crossingDistance) {
              polygon.crossingDistance = parseFloat(polygon.crossingDistance.toFixed(3))
            }
          })
        }

        if (normalizedData.directionPolygons && normalizedData.directionPolygons.length > 0) {
          normalizedData.directionPolygons.forEach(polygon => {
            if (polygon.pts) {
              polygon.pts.forEach(pt => {
                pt[0] = parseFloat((pt[0] / this.videoSrcWidth).toFixed(3))
                pt[1] = parseFloat((pt[1] / this.videoSrcHeight).toFixed(3))
              })
            }
          })
        }

        if (normalizedData.dwellPolygons && normalizedData.dwellPolygons.length > 0) {
          normalizedData.dwellPolygons.forEach(polygon => {
            if (polygon.pts) {
              polygon.pts.forEach(pt => {
                pt[0] = parseFloat((pt[0] / this.videoSrcWidth).toFixed(3))
                pt[1] = parseFloat((pt[1] / this.videoSrcHeight).toFixed(3))
              })
            }
          })
        }

        if (normalizedData.speedLines && normalizedData.speedLines.length > 0) {
          normalizedData.speedLines.forEach(line => {
            if (line.length === 2) {
              line[0][0] = parseFloat((line[0][0] / this.videoSrcWidth).toFixed(3))
              line[0][1] = parseFloat((line[0][1] / this.videoSrcHeight).toFixed(3))
              line[1][0] = parseFloat((line[1][0] / this.videoSrcWidth).toFixed(3))
              line[1][1] = parseFloat((line[1][1] / this.videoSrcHeight).toFixed(3))
            }
          })
        }

        if (normalizedData.eventLines && normalizedData.eventLines.length > 0) {
          normalizedData.eventLines.forEach(line => {
            if (line.length === 2) {
              line[0][0] = parseFloat((line[0][0] / this.videoSrcWidth).toFixed(3))
              line[0][1] = parseFloat((line[0][1] / this.videoSrcHeight).toFixed(3))
              line[1][0] = parseFloat((line[1][0] / this.videoSrcWidth).toFixed(3))
              line[1][1] = parseFloat((line[1][1] / this.videoSrcHeight).toFixed(3))
            }
          })
        }

        if (normalizedData.approaches) {
          Object.keys(normalizedData.approaches).forEach(key => {
            normalizedData.approaches[key][0] = parseFloat((normalizedData.approaches[key][0] / this.videoSrcWidth).toFixed(3))
            normalizedData.approaches[key][1] = parseFloat((normalizedData.approaches[key][1] / this.videoSrcHeight).toFixed(3))
          })
        }

        delete normalizedData.geoLines
        delete normalizedData.crossingDistanceGeoLines
        delete normalizedData.isNew

        return normalizedData
      }
      return null
    },
    async saveData() {
      console.log('On save data')
      if (this.videoSrcWidth && this.videoSrcHeight) {

        this.setStoreData()
        let saveData = JSON.parse(JSON.stringify(this.storeData))

        let dict = {};
        saveData.approaches.forEach(approach => {
          let key = Object.keys(approach)[0]
          dict[key] = approach[key]
        })
        saveData.approaches = dict
        let normalized = this.normalize(saveData)
        let payload = JSON.parse(JSON.stringify(this.selectedCalibration))

        payload.pixelCalibration = saveData
        payload.calibration = normalized


        delete payload.validFromPicker
        delete payload.validToPicker
        delete payload.isNew
        delete payload.localCaptureStart
        delete payload.localCaptureEnd
        delete payload.availableFiles
        delete payload.videoUrl

        if (payload.calibration === null) {
          this.showToast('Normalized calibration not created. ', 'is-danger')
        }

        this.isLoading = true

        if (payload.isEdit) {
          console.log('Is edit: ', true)
          delete payload.isEdit
          let response = await this.editCalibration(payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.showToast('Video Calibration successfully updated', 'is-success')
            await this.selectCalibration(null)
          }
        } else {
          let response = await this.saveCalibration(payload)
          if (response.errorMessage) {
            this.showToast(response.errorMessage, 'is-danger')
          } else {
            this.showToast('Video Calibration successfully created', 'is-success')
            await this.selectCalibration(null)
          }
        }


        this.isLoading = false
        console.log('SAve Payload: ', payload)
      } else {
        this.showToast('Can not create calibration. Video is not loaded correctly.', 'is-danger')
      }
    },
    onLinesDrawn(features) {

      if (this.tabIndex === 3) {
        this.videoData.geoLines = features

        let allDrawings = this.videoData.geoLines

        let linePairs = []
        let pickedDistances = []
        if (allDrawings && allDrawings.features && allDrawings.features.length > 1) {
          let features = allDrawings.features
          for (let i = 0; i < features.length - 1; i++) {
            linePairs.push({first: features[i].geometry.coordinates, second: features[i + 1].geometry.coordinates})
          }
        }

        if (linePairs.length > 0) {
          let options = {units: "kilometers"};
          linePairs.forEach(linePair => {
            let distOne = distance(point(linePair.first[0]), point(linePair.second[0]), options);
            let distTwo = distance(point(linePair.first[0]), point(linePair.second[1]), options);

            let pick = distOne < distTwo ? distOne : distTwo
            pick = parseFloat((pick * 1000.0).toFixed(3))

            pickedDistances.push(pick)
            console.log('Distance: ', `First dist ${distOne} km, Second dist ${distTwo} PICK: ${pick}m`)
          })
        }

        this.videoData.speedDistances = pickedDistances
      } else if (this.tabIndex === 0) {
        this.videoData.crossingDistanceGeoLines = features

        let allDrawings = this.videoData.crossingDistanceGeoLines
        console.log('All Drivings Crossing', allDrawings)

        this.videoData.stagePolygons.forEach(poly => {
          poly.storeData.crossingDistance = null
        })

        if (allDrawings && allDrawings.features && allDrawings.features.length > 0) {
          allDrawings.features.forEach(feature => {
            let options = {units: "kilometers"};
            let coordinates = feature.geometry.coordinates
            let dist = distance(point(coordinates[0]), point(coordinates[1]), options);
            dist = parseFloat((dist * 1000.0).toFixed(3))

            let polyIndex = this.videoData.stagePolygons.findIndex(polygon => polygon.id === feature.id)
            if (polyIndex !== -1) {
              this.videoData.stagePolygons[polyIndex].storeData.crossingDistance = dist
            }
          })
        }
      }

      this.refreshData()
    },
    setStoreData() {
      this.storeData = {
        stagePolygons: this.videoData.stagePolygons.map(poly => poly.storeData),
        directionPolygons: this.videoData.directionPolygons.map(poly => poly.storeData),
        dwellPolygons: this.videoData.dwellPolygons.map(poly => poly.storeData),
        speedLines: this.videoData.speedLines.map(line => line.storeData),
        eventLines: this.videoData.eventLines.map(eLine => eLine.storeData),
        approaches: this.videoData.approaches.map(approach => approach.storeData),
        speedDistances: this.videoData.speedDistances,
        geoLines: this.videoData.geoLines,
        crossingDistanceGeoLines: this.videoData.crossingDistanceGeoLines
      }

      console.log('Set store data: ', this.storeData)
      console.log('Set store data video: ', this.videoData)
    },
    refreshData() {
      if (this.videoData && this.videoSrcWidth !== 0 && this.videoSrcHeight !== 0 && this.selectedCalibration) {
        setTimeout(() => {
          this.setStoreData()
          console.log('Refresh data: ', this.storeData)
          this.parseData(this.storeData)
        }, 500)
      }
    },
    onColorChanged() {
      this.refreshData()
    },
    onHover(imageId) {
      this.$refs.videoDraw.onDeleteHover(imageId)
    },
    onLeave(imageId) {
      this.$refs.videoDraw.onDeleteLeave(imageId)
    },
    onDelete(imageId) {
      if (this.selectCalibration && this.selectedCalibration.isNew) {
        this.$refs.videoDraw.onDelete(imageId)
      }
    },
    onApproachKeyChanged(imageId, event) {
      console.log('Approach key changed', imageId, event)

      let index = this.videoData.approaches.findIndex(app => app.id === imageId)
      if (index !== -1) {
        let oldData = JSON.parse(JSON.stringify(this.videoData.approaches[index].storeData))
        let obj = {}
        obj[event] = Object.values(oldData)[0]
        this.videoData.approaches[index].storeData = obj
        this.videoData.approaches[index].key = event
        this.videoData.approaches[index].value = JSON.stringify(Object.values(oldData)[0])
        this.videoData.approaches[index].drawData.text = event
      }

      let allDrawData = [
        ...this.videoData.stagePolygons.map(poly => poly.drawData),
        ...this.videoData.directionPolygons.map(poly => poly.drawData),
        ...this.videoData.dwellPolygons.map(poly => poly.drawData),
        ...this.videoData.speedLines.map(line => line.drawData),
        ...this.videoData.eventLines.map(eLine => eLine.drawData),
        ...this.videoData.approaches.map(app => app.drawData),
        ...this.videoData.measurements
      ]

      this.setStoreData()

      console.log('All DATA: ', allDrawData)

      this.$refs.videoDraw.setInputData(allDrawData)
    },
    onPolyDataChanged() {
      this.$nextTick(() => {
        this.setStoreData()
      })
    },
    parseData(storeData) {
      console.log('Video json', storeData)

      let currentWidth = this.$refs.videoDraw.getSize().width
      let currentHeight = this.$refs.videoDraw.getSize().height

      let diffX = currentWidth / this.videoSrcWidth
      let diffY = currentHeight / this.videoSrcHeight

      let settings = this.$refs.videoDraw.getSettings()

      this.videoData = {
        stagePolygons: [],
        directionPolygons: [],
        dwellPolygons: [],
        speedLines: [],
        eventLines: [],
        approaches: [],
        measurements: [],
        geoLines: storeData ? storeData.geoLines : null,
        crossingDistanceGeoLines: storeData ? storeData.crossingDistanceGeoLines : null,
        speedDistances: storeData ? storeData.speedDistances : null,
      }

      if (storeData === null) {
        this.$refs.videoDraw.setInputData([])
        return
      }

      if (storeData) {
        if (storeData.stagePolygons) {
          storeData.stagePolygons.forEach((polygon, index) => {
            let coordinates = []
            polygon.pts.forEach(pt => {
              console.log('pt:', pt[1])
              let point = {
                x: Math.round(pt[0] * diffX),
                y: Math.round(pt[1] * diffY)
              }
              coordinates.push(point)
            })
            let drawData = {
              id: `${VideoCalibrationCategory.STAGE_POLYGON}_${index}`,
              type: 'polygon',
              from: coordinates[0],
              coordinates: coordinates.slice(1),
              text: null,
              textPosition: null,
              renderedDimensions: {width: currentWidth, height: currentHeight},
              category: VideoCalibrationCategory.STAGE_POLYGON
            }

            let merged = Object.assign(drawData, settings)

            this.videoData.stagePolygons.push({
              id: merged.id,
              storeData: {
                polyName: polygon.polyName,
                crossingDistance: polygon.crossingDistance,
                pts: polygon.pts,
              },
              ptsString: JSON.stringify(polygon.pts),
              drawData: merged,
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          })
        }
        if (storeData.directionPolygons) {
          storeData.directionPolygons.forEach((polygon, index) => {
            let coordinates = []
            polygon.pts.forEach(pt => {
              let point = {
                x: Math.round(pt[0] * diffX),
                y: Math.round(pt[1] * diffY)
              }
              coordinates.push(point)
            })
            let drawData = {
              id: `${VideoCalibrationCategory.DIRECTION_POLYGON}_${index}`,
              type: 'polygon',
              from: coordinates[0],
              coordinates: coordinates.slice(1),
              text: null,
              textPosition: null,
              renderedDimensions: {width: currentWidth, height: currentHeight},
              category: VideoCalibrationCategory.DIRECTION_POLYGON
            }

            let merged = Object.assign(drawData, settings)

            this.videoData.directionPolygons.push({
              id: merged.id,
              storeData: {
                polyName: polygon.polyName,
                pts: polygon.pts,
              },
              ptsString: JSON.stringify(polygon.pts),
              drawData: merged,
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          })
        }
        if (storeData.dwellPolygons) {
          storeData.dwellPolygons.forEach((polygon, index) => {
            let coordinates = []
            polygon.pts.forEach(pt => {
              let point = {
                x: Math.round(pt[0] * diffX),
                y: Math.round(pt[1] * diffY)
              }
              coordinates.push(point)
            })
            let drawData = {
              id: `${VideoCalibrationCategory.DWELL_POLYGON}_${index}`,
              type: 'polygon',
              from: coordinates[0],
              coordinates: coordinates.slice(1),
              text: null,
              textPosition: null,
              renderedDimensions: {width: currentWidth, height: currentHeight},
              category: VideoCalibrationCategory.DWELL_POLYGON
            }

            let merged = Object.assign(drawData, settings)

            this.videoData.dwellPolygons.push({
              id: merged.id,
              storeData: {
                pedDirection: polygon.pedDirection,
                pts: polygon.pts,
              },
              ptsString: JSON.stringify(polygon.pts),
              drawData: merged,
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          })
        }
        if (storeData.speedLines) {
          storeData.speedLines.forEach((line, index) => {
            let coordinates = []
            line.forEach(pt => {
              let point = {
                x: Math.round(pt[0] * diffX),
                y: Math.round(pt[1] * diffY)
              }
              coordinates.push(point)
            })
            let drawData = {
              id: `${VideoCalibrationCategory.SPEED_LINE}_${index}`,
              type: 'line',
              from: coordinates[0],
              coordinates: coordinates.slice(1),
              text: null,
              textPosition: null,
              renderedDimensions: {width: currentWidth, height: currentHeight},
              category: VideoCalibrationCategory.SPEED_LINE
            }

            let merged = Object.assign(drawData, settings)

            this.videoData.speedLines.push({
              id: merged.id,
              storeData: line,
              ptsString: JSON.stringify(line),
              drawData: merged,
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          })
        }
        if (storeData.eventLines) {
          console.log('Store Data Event lines: ', storeData.eventLines)
          storeData.eventLines.forEach((eventLine, index) => {
            let coordinates = []
            eventLine.forEach(pt => {
              console.log('pt:', pt[1])
              let point = {
                x: Math.round(pt[0] * diffX),
                y: Math.round(pt[1] * diffY)
              }
              coordinates.push(point)
            })
            let drawData = {
              id: `${VideoCalibrationCategory.EVENT_LINE}_${index}`,
              type: 'line',
              from: coordinates[0],
              coordinates: coordinates.slice(1),
              text: null,
              textPosition: null,
              renderedDimensions: {width: currentWidth, height: currentHeight},
              category: VideoCalibrationCategory.EVENT_LINE
            }

            let merged = Object.assign(drawData, settings)

            this.videoData.eventLines.push({
              id: merged.id,
              storeData: eventLine,
              ptsString: JSON.stringify(eventLine),
              drawData: merged,
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          })
        }
        if (storeData.approaches) {
          console.log('Parse approaches', storeData.approaches)
          storeData.approaches.forEach((approach, index) => {

            let value = Object.values(approach)[0]
            let key = Object.keys(approach)[0]

            let point = {
              x: Math.round(value[0] * diffX),
              y: Math.round(value[1] * diffY)
            }
            let drawData = {
              id: `${VideoCalibrationCategory.APPROACH}_${index}`,
              type: 'dot',
              from: point,
              coordinates: [],
              text: key,
              textPosition: point,
              renderedDimensions: {width: currentWidth, height: currentHeight},
              category: VideoCalibrationCategory.APPROACH
            }

            let merged = Object.assign(drawData, settings)

            this.videoData.approaches.push({
              id: merged.id,
              storeData: approach,
              key: key,
              value: JSON.stringify(value),
              drawData: merged,
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          })
        }
      }

      this.setMeasurements()

      let allDrawData = [
        ...this.videoData.stagePolygons.map(poly => poly.drawData),
        ...this.videoData.directionPolygons.map(poly => poly.drawData),
        ...this.videoData.dwellPolygons.map(poly => poly.drawData),
        ...this.videoData.speedLines.map(line => line.drawData),
        ...this.videoData.eventLines.map(eLine => eLine.drawData),
        ...this.videoData.approaches.map(app => app.drawData),
        ...this.videoData.measurements
      ]

      console.log('All DATA: ', allDrawData)
      this.$refs.videoDraw.setInputData(allDrawData)
    },
    setMeasurements() {

      let currentWidth = this.$refs.videoDraw.getSize().width
      let currentHeight = this.$refs.videoDraw.getSize().height

      let settings = this.$refs.videoDraw.getSettings()

      //add line measurements
      this.videoData.measurements = []
      let lines = this.videoData.speedLines.map(line => line.drawData)

      console.log('Set measurements lies:', lines)


      for (let i = 0; i < lines.length - 1; i++) {

        let first = lines[i].from
        let second = lines[i + 1].from

        let middleX = (first.x + second.x) / 2
        let middleY = (first.y + second.y) / 2
        let measureLine = {
          id: `measure_${i}`,
          type: 'line',
          from: first,
          coordinates: [second],
          text: this.storeData.speedDistances && this.storeData.speedDistances.length > i ? this.storeData.speedDistances[i] + ' m' : 'NOT MEASURED',
          textPosition: {x: middleX, y: middleY},
          renderedDimensions: {width: currentWidth, height: currentHeight},
          category: 'measurement_line'
        };

        let merged = Object.assign(measureLine, settings)
        merged.color = '#ffdb00'
        merged.width = 3
        this.videoData.measurements.push(merged)
      }

      console.log('Measurement lines:', this.videoData.measurements)
    },
    onDeleteImage(imageId) {
      console.log('On delete image', imageId)
      let polyIndex = this.videoData.stagePolygons.findIndex(poly => poly.id === imageId)
      if (polyIndex !== -1) {
        this.videoData.stagePolygons.splice(polyIndex, 1)
        this.$refs.mapDraw.deleteFeature(polyIndex)
      }

      let dirIndex = this.videoData.directionPolygons.findIndex(poly => poly.id === imageId)
      if (dirIndex !== -1) {
        this.videoData.directionPolygons.splice(dirIndex, 1)
      }

      let dwellIndex = this.videoData.dwellPolygons.findIndex(poly => poly.id === imageId)
      if (dwellIndex !== -1) {
        this.videoData.dwellPolygons.splice(dwellIndex, 1)
      }

      let speedLineIndex = this.videoData.speedLines.findIndex(line => line.id === imageId)
      if (speedLineIndex !== -1) {
        this.videoData.speedLines.splice(speedLineIndex, 1)
        this.$refs.mapDraw.deleteFeature(speedLineIndex)
        this.setMeasurements()

      }
      let eLineIndex = this.videoData.eventLines.findIndex(l => l.id === imageId)
      if (eLineIndex !== -1) {
        this.videoData.eventLines.splice(eLineIndex, 1)
      }
      let aIndex = this.videoData.approaches.findIndex(a => a.id === imageId)
      if (aIndex !== -1) {
        this.videoData.approaches.splice(aIndex, 1)
      }

      this.refreshData()


    },
    onDrawFinished(images) {
      console.log('On draw finished', images)

      let diffX = this.videoSrcWidth / this.$refs.videoDraw.getSize().width
      let diffY = this.videoSrcHeight / this.$refs.videoDraw.getSize().height
      //check is deleted


      images.forEach(image => {

        if (image.category === VideoCalibrationCategory.STAGE_POLYGON) {
          let points = []
          points.push(image.from)
          points.push(...image.coordinates)
          let pts = points.map(point => [Math.round(point.x * diffX), Math.round(point.y * diffY)])
          let index = this.videoData.stagePolygons.findIndex(poly => poly.id === image.id)
          if (index !== -1) {
            this.videoData.stagePolygons[index].drawData = image
            this.videoData.stagePolygons[index].pts = pts
          } else {

            let crossingDistance = null

            if (this.videoData.crossingDistanceGeoLines && this.videoData.crossingDistanceGeoLines.features
                && this.videoData.crossingDistanceGeoLines.features.length > 0) {

              let featureIndex = this.videoData.crossingDistanceGeoLines.features.findIndex(feature => feature.id === image.id)
              if (featureIndex !== -1) {
                let options = {units: "kilometers"};
                let coordinates = this.videoData.crossingDistanceGeoLines.features[featureIndex].geometry.coordinates
                let dist = distance(point(coordinates[0]), point(coordinates[1]), options);
                crossingDistance = (dist * 1000.0).toFixed(3)
              }
            }

            this.videoData.stagePolygons.push(
                {
                  id: image.id,
                  storeData: {
                    polyName: null,
                    crossingDistance: crossingDistance,
                    pts: pts,
                  },
                  ptsString: JSON.stringify(pts),
                  drawData: image,
                  originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
                }
            )
          }
        } else if (image.category === VideoCalibrationCategory.DIRECTION_POLYGON) {
          let points = []
          points.push(image.from)
          points.push(...image.coordinates)
          let pts = points.map(point => [Math.round(point.x * diffX), Math.round(point.y * diffY)])
          let index = this.videoData.directionPolygons.findIndex(poly => poly.id === image.id)
          if (index !== -1) {
            this.videoData.directionPolygons[index].drawData = image
            this.videoData.directionPolygons[index].pts = pts
          } else {
            this.videoData.directionPolygons.push(
                {
                  id: image.id,
                  storeData: {
                    polyName: null,
                    pts: pts,
                  },
                  ptsString: JSON.stringify(pts),
                  drawData: image,
                  originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
                }
            )
          }
        } else if (image.category === VideoCalibrationCategory.DWELL_POLYGON) {
          let points = []
          points.push(image.from)
          points.push(...image.coordinates)
          let pts = points.map(point => [Math.round(point.x * diffX), Math.round(point.y * diffY)])
          let index = this.videoData.dwellPolygons.findIndex(poly => poly.id === image.id)
          if (index !== -1) {
            this.videoData.dwellPolygons[index].drawData = image
            this.videoData.dwellPolygons[index].pts = pts
          } else {
            this.videoData.dwellPolygons.push(
                {
                  id: image.id,
                  storeData: {
                    pedDirection: null,
                    pts: pts,
                  },
                  ptsString: JSON.stringify(pts),
                  drawData: image,
                  originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
                }
            )
          }
        } else if (image.category === VideoCalibrationCategory.SPEED_LINE) {
          let points = []
          points.push(image.from)
          points.push(...image.coordinates)
          let pts = points.map(point => [Math.round(point.x * diffX), Math.round(point.y * diffY)])
          let index = this.videoData.speedLines.findIndex(line => line.id === image.id)
          if (index !== -1) {
            this.videoData.speedLines[index].drawData = image
            this.videoData.speedLines[index].storeData = pts
          } else {
            this.videoData.speedLines.push(
                {
                  id: image.id,
                  storeData: pts,
                  ptsString: JSON.stringify(pts),
                  drawData: image,
                  originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
                }
            )
          }
        } else if (image.category === VideoCalibrationCategory.EVENT_LINE) {
          let points = []
          points.push(image.from)
          points.push(...image.coordinates)
          let pts = points.map(point => [Math.round(point.x * diffX), Math.round(point.y * diffY)])
          let index = this.videoData.eventLines.findIndex(line => line.id === image.id)
          if (index !== -1) {
            this.videoData.eventLines[index].drawData = image
            this.videoData.eventLines[index].storeData = pts
          } else {
            this.videoData.eventLines.push(
                {
                  id: image.id,
                  storeData: pts,
                  ptsString: JSON.stringify(pts),
                  drawData: image,
                  originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
                }
            )
          }
        } else if (image.category === VideoCalibrationCategory.APPROACH) {
          let pts = [Math.round(image.from.x * diffX), Math.round(image.from.y * diffY)]
          let obj = {}
          obj[image.text] = pts


          let index = this.videoData.approaches.findIndex(app => app.id === image.id)
          if (index !== -1) {
            this.videoData.approaches[index].drawData = image
            this.videoData.approaches[index].storeData = obj
          } else {
            this.videoData.approaches.push({
              id: image.id,
              storeData: obj,
              drawData: image,
              key: image.text,
              value: JSON.stringify(pts),
              originalDimensions: {w: this.videoSrcWidth, h: this.videoSrcHeight}
            })
          }
        }
      })

      console.log('New video data: ', this.videoData)
      this.setStoreData()
      this.parseData(this.storeData)
    },
    onTabChanged(index) {
      console.log('On tab changed: ', index)
      if (index === 0) {
        this.$refs.videoDraw.setSelectedCategory('polygon', VideoCalibrationCategory.STAGE_POLYGON)
      } else if (index === 1) {
        this.$refs.videoDraw.setSelectedCategory('polygon', VideoCalibrationCategory.DIRECTION_POLYGON)
      } else if (index === 2) {
        this.$refs.videoDraw.setSelectedCategory('polygon', VideoCalibrationCategory.DWELL_POLYGON)
      } else if (index === 3) {
        this.$refs.videoDraw.setSelectedCategory('line', VideoCalibrationCategory.SPEED_LINE)
      } else if (index === 4) {
        this.$refs.videoDraw.setSelectedCategory('line', VideoCalibrationCategory.EVENT_LINE)
      } else if (index === 5) {
        this.$refs.videoDraw.setSelectedCategory('dot', VideoCalibrationCategory.APPROACH)
      } else {
        this.$refs.videoDraw.setSelectedCategory('none', 'none')
      }
    },
    onVideoMetaDataLoaded(e) {
      this.videoSrcWidth = e.target.videoWidth
      this.videoSrcHeight = e.target.videoHeight
      console.log('Width&Height of video', this.videoSrcWidth, this.videoSrcHeight)
      this.$refs.videoDraw.setOriginalVideoDimensions(this.videoSrcWidth, this.videoSrcHeight)
      this.isLoading = false
      this.videoLoaded = true
      //this.$refs.videoRef.play()
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    onCloneCalibration(calibration) {
      this.$refs.actionsBar.cloneCalibration(calibration)
    },
    async onEditCalibration(calibration) {
      console.log('Calibration: ', calibration)
      let calib = JSON.parse(JSON.stringify(calibration))
      calib.isNew = true
      calib.isEdit = true
      await this.selectCalibration(calib)
    }
  },
  computed: {
    ...mapGetters({
      calibrations: 'dataProcessingModule/getSelectedVideoCalibrations',
      selectedCalibration: 'dataProcessingModule/getSelectedCalibration',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice',
      allProjects: 'projectsModule/getAllProjects',
      selectedProject: 'dataProcessingModule/getSelectedProcessingProject',
    }),
  },
  watch: {
    selectedCalibration() {
      console.log('Selected calibration: ', this.selectedCalibration)
      this.convertAndSetData()
      this.parseData(this.storeData)
      this.$refs.mapDraw.setFeature(this.storeData ? this.storeData.geoLines : null)
      this.$refs.mapDraw.setCrossingDistanceFeature(this.storeData ? this.storeData.crossingDistanceGeoLines : null)
      this.$refs.mapDraw.addOrRemoveDraw()

      if (this.selectedCalibration && this.$refs.videoRef.src !== this.selectedCalibration.videoUrl) {
        this.isLoading = true
        this.videoLoaded = false
        this.$refs.videoRef.src = this.selectedCalibration.videoUrl
      } else if (this.$refs.videoRef.src !== this.selectedDevice.videoUrl) {
        this.isLoading = true
        this.videoLoaded = false
        this.$refs.videoRef.src = this.selectedDevice.videoUrl
      }
    }
  }
}
</script>

<style scoped>
.video-img {
  background: white;
}

.canvas-style {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.shadow {
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 14%);
}

</style>