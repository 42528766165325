export default () => ({
    selectedMonitoringProject: null,
    searchQueryData: {
        query: '',
        sort: {icon: 'sort-alphabetical-ascending', text: 'Alphabetically ascending', value: 'ASCENDING'},
        projectType: {icon: '', text: 'All', value: 'ALL'},
        active: {icon: 'calendar-check', text: 'Active', value: 'Active'}
    },
    monitoringData: null
});
