<template>
  <div class="column">
    <div class="columns">
      <div class="column">
        <div v-if="initialValidation.pedTasks === null" style="height: 48px"
             class="is-flex is-align-items-center dashed-border-small ml-0 mr-0 mt-auto mb-auto">
          <h4 class="is-size-6 ml-auto mr-auto has-text-grey-light has-text-weight-light">{{ getMessage() }}</h4>
        </div>
        <div v-else style="height: 48px" class="is-flex mt-auto mb-auto">
          <b-tooltip label="Stage #3 - Pedestrian Processing" type="is-dark" position="is-left" :delay="1000">
            <div class="clickable-info has-text-centered is-flex mr-1" style="height: 48px; width: 48px; background: rgba(30,43,87,0.8);cursor: default">
              <div class="is-size-6 mt-auto mb-auto ml-auto mr-auto has-text-white">#3</div>
            </div>
          </b-tooltip>
          <hr class="has-background-grey-lighter m-0" style="width: 1px; height: 48px">
          <div class="clickable-info has-text-centered is-flex ml-1" style="height: 48px; width: 80px"
               :style="statusStyle()">
            <div class="is-size-7 mt-auto mb-auto ml-auto mr-auto has-text-white">{{ initialValidation.pedTasks.status }}</div>
          </div>
          <div class="clickable-info ml-2 is-flex pt-2 pl-4" style="flex-grow: 1">
            <div class="m-0" style="flex: 1;max-width: 48px" v-if="initialValidation.pedTasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">VERSION</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  initialValidation.pedTasks.version
                }}
              </div>
            </div>
            <div style="flex: 0.5" class="is-flex" v-if="initialValidation.pedTasks.status !== 'CREATED'">
              <hr class="mt-0 mb-1 ml-auto mr-auto has-background-grey-lighter" style="width: 1px; height: 30px">
            </div>
            <div class="m-0" style="flex: 1">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px;">
                {{ initialValidation.pedTasks.status === 'CREATED' ? 'CREATED TASKS' : 'QUEUED' }}
              </div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">
                {{
                  initialValidation.pedTasks.status === 'CREATED' ? initialValidation.pedTasks.created : initialValidation.pedTasks.queued
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="initialValidation.pedTasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">IN PROGRESS</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  initialValidation.pedTasks.inProgress
                }}
              </div>
            </div>
            <div style="flex: 0.5" class="is-flex" v-if="initialValidation.pedTasks.status !== 'CREATED'">
              <hr class="mt-0 mb-1 ml-auto mr-auto has-background-grey-lighter" style="width: 1px; height: 30px">
            </div>
            <div class="m-0" style="flex: 1" v-if="initialValidation.pedTasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">FAILED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  initialValidation.pedTasks.failed
                }}
              </div>
            </div>
            <div class="m-0" style="flex: 1" v-if="initialValidation.pedTasks.status !== 'CREATED'">
              <div class="has-text-grey-light has-text-weight-normal" style="font-size: 10px">COMPLETED</div>
              <div class="has-text-black has-text-weight-bold" style="line-height: 1">{{
                  initialValidation.pedTasks.completed
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-flex is-flex-direction-row" style="max-width: 96px">
        <view-guard :permission="'data-processing:create-tasks'" class="ml-auto mr-0 mt-auto mb-auto">

          <b-tooltip v-if="refreshing"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Please Wait..." :delay="1000" type="is-dark" position="is-left">
            <div v-if="refreshing" class="my-loader mt-1 mb-1"></div>
          </b-tooltip>


          <b-tooltip v-if="initialValidation.pedTasks && (initialValidation.pedTasks.status === 'COMPLETED') && !refreshing"
                     class="ml-auto mr-0 mt-auto mb-auto" label="Re Run Pedestrian Processing" :delay="1000" type="is-dark" position="is-left">
            <b-icon
                icon="restart"
                class="clickable" size="is-medium"
                style="color: rgba(30,43,87,0.8)"
                @click.native="onRunPedestrianProcessing"></b-icon>
          </b-tooltip>
          <b-tooltip v-if="initialValidation.pedTasks && (initialValidation.pedTasks.status === 'COMPLETED') && !refreshing"
                     class="ml-2 mr-0 mt-auto mb-auto" label="Run Full Processing" :delay="1000" type="is-dark" position="is-left">
            <b-icon
                icon="play-circle"
                class="clickable" size="is-medium"
                style="color: rgba(30,43,87,1)"
                @click.native="onRunFullProcessing"></b-icon>
          </b-tooltip>
          <b-tooltip v-if="initialValidation.pedTasks && (initialValidation.pedTasks.status === 'FAILED') && !refreshing"
                     class="ml-0 mr-0 mt-auto mb-auto" :label="checkPermission() === false ? 'Please contact System Admin' : 'Reset Pedestrian Processing'" :delay="1000" type="is-dark" position="is-left">
            <b-button type="is-primary" @click="onResetStep" :disabled="checkPermission() === false" style="background-color: rgba(30,43,87,0.8)">Reset</b-button>
          </b-tooltip>
          <b-tooltip v-else-if="initialValidation.pedTasks && initialValidation.pedTasks.status === 'RUNNING' && !refreshing"
                     class="ml-auto mr-auto mt-auto mb-auto" label="Please Wait..." :delay="1000" type="is-dark" position="is-left">
            <b-icon
                icon="progress-clock"
                class="clickable" size="is-medium"></b-icon>

            <b-progress size="is-small" :value="progressValue"></b-progress>
          </b-tooltip>
        </view-guard>
      </div>
    </div>
  </div>
</template>

<script>
import ViewGuard from "@/components/view-guard/view-guard.vue";
import {isAllowed} from "@/utils/utils";

export default {
  name: "ped-validation-info",
  components: {ViewGuard},
  props: {
    initialValidation: {
      type: Object
    },
    refreshing: {
      type: Boolean
    }
  },
  data(){
    return {
      progressValue:40,
      //refreshing: false
    }
  },
  methods: {
    setProgress(progress){
      this.progressValue = progress
    },
    checkPermission() {
      return isAllowed(this.$store.getters.getMe, 'data-processing:reset-step')
    },
/*    setRefreshing(refresh){
      this.refreshing = refresh
    },*/
    getMessage() {
      if (this.allConditions()) {
        return 'This device has no tasks'
      } else {
        return 'Conditions missing'
      }
    },
    statusStyle() {
      if (this.initialValidation.pedTasks && this.initialValidation.pedTasks.status) {
        if (this.initialValidation.pedTasks.status === 'CREATED') {
          return {background: '#8C84FF'}
        } else if (this.initialValidation.pedTasks.status === 'RUNNING') {
          return {background: '#D8BA13'}
        } else if (this.initialValidation.pedTasks.status === 'COMPLETED') {
          return {background: '#1CAB80'}
        }else if (this.initialValidation.pedTasks.status === 'FAILED') {
          return {background: '#e02c2c'}
        }
      }
      return {background: '#000000'}
    },
    initialConditions(){
      if(this.initialValidation.validationChart && this.initialValidation.validationChart._id){
        return true
      }
      return false
    },
    onRunFullProcessing() {
      console.log('On Show full results:', this.initialValidation)
      //this.showToast('Call next action from here', 'is-success')
      this.$emit('onRunFullProcessing')
    },
    onRunPedestrianProcessing() {
      console.log('On Show full results:', this.initialValidation)
      //this.showToast('Call next action from here', 'is-success')
      this.$emit('onRunPedestrianProcessing')
    },
    onShowInitialResults() {
      console.log('On Show initial results:', this.initialValidation)
      //this.showToast('Call next action from here', 'is-success')
      this.$emit('onShowInitialResults')
    },
    onResetStep(){
      this.$emit('onResetStep','pedestrian')
    }
  }
}
</script>

<style scoped lang="scss">
.dashed-border-small {
  padding: 8px 8px;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.clickable-info {
  background: #f4f4f4;
  border-radius: 0.3rem;
}

/*.clickable-info:hover {
  cursor: pointer;
}*/

.clickable:hover {
  cursor: pointer;
}

.dashed-border-small {
  padding: 8px 8px;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.my-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #1cab80;
  border-bottom: 4px solid #1cab80;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>