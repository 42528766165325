<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-site-devices ref="addDevices" :site="site"></add-site-devices>
    <edit-site-plan ref="editSitePlan" :site="site"></edit-site-plan>
    <b-collapse
        class="card shadow"
        animation="slide"
        v-model="isOpen"
        style="border-radius: 16px; background-color: #f9f9f9;"
        aria-id="contentIdForA11y3">
      <template #trigger>
        <div
            class="card-header shadow"
            role="button"
            aria-controls="contentIdForA11y3"
            style="border-radius: 16px; background-color: white; height: 72px;">
          <div class="card-header-title">
            <div class="columns" style="width: 100%">
              <div class="column is-flex" style="max-width: 42px" @click.stop="">
                <b-checkbox class="mt-auto mb-auto" type="is-info" v-model="isSelected" @input="onSelectionChanged" :disabled="selectedDevices.length > 0"></b-checkbox>
              </div>
              <div class="column is-1 m-0 p-0 ml-1 mr-1 is-flex"
                   style="width: 12px; max-width: 12px; align-self: center;">
                <b-tooltip :label="site.status" position="is-top" style="cursor: default; height: 100%">
                  <div class="status-indicator" :style="{'background': planStatusColor(site.status)}"></div>
                </b-tooltip>
              </div>
              <div class="column">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal">Site name</div>
                <div class="has-text-black">{{ site.siteName }}</div>
              </div>
              <div class="column">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal">Devices</div>
                <div class="has-text-black">{{ countDevices() }}</div>
              </div>
              <div class="column">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal">Installation date</div>
                <div v-if="getDate(TaskType.INSTALLATION)" class="has-text-black has-text-weight-normal">
                  {{ getDate(TaskType.INSTALLATION) }}
                </div>
                <div v-else class="has-text-warning has-text-weight-normal">Undefined</div>
              </div>
              <div class="column">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal">Decommission date</div>
                <div v-if="getDate(TaskType.DECOMMISSION)" class="has-text-black has-text-weight-normal">
                  {{ getDate(TaskType.DECOMMISSION) }}
                </div>
                <div v-else class="has-text-warning has-text-weight-normal">Undefined</div>
              </div>
              <div class="column m-0 p-0 pl-1">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal mt-3">Install Crew</div>
                <div v-if="installationCrew" class="is-flex">
                  <user-avatar style="margin-right: -3px" outline-color="#f9f9f9" :is-small="true"
                               v-for="userId in installationCrew" :user="getUser(userId)" :key="userId"></user-avatar>
                </div>
                <div v-else class="has-text-warning has-text-weight-normal">Undefined</div>
              </div>
              <div class="column m-0 p-0 pl-1">
                <div class="is-size-7 has-text-grey-light has-text-weight-normal mt-3">Decommission Crew</div>
                <div v-if="decommissionCrew" class="is-flex">
                  <user-avatar style="margin-right: -3px" outline-color="#f9f9f9" :is-small="true"
                               v-for="userId in decommissionCrew" :user="getUser(userId)" :key="userId"></user-avatar>
                </div>
                <div v-else class="has-text-warning has-text-weight-normal">Undefined</div>
              </div>
              <div class="column mr-0 pr-0">
                <div class="is-flex is-flex-direction-row mt-3 is-align-items-end">
                  <div class="ml-auto"></div>
                  <view-guard :permission="'site-plan:create-plan-devices'" class="mr-1">
                    <b-tooltip label="Add New Device/s" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="onAddDevices">
                        <b-icon icon="plus-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                  <view-guard :permission="'site-plan:update'" class="mr-1">
                    <b-tooltip label="Edit Site Plan" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="onEditSitePlan">
                        <b-icon icon="pencil-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                  <view-guard v-if="site && site.siteId" :permission="'site-plan:move'" class="mr-1">
                    <b-tooltip label="Move Site To Project" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="onMoveSitePlan">
                        <b-icon icon="arrow-top-right-thin-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                  <view-guard :permission="'site-plan:delete'" class="mr-1">
                    <b-tooltip v-if="site.status === 'PLANNING'" label="Delete Site Plan" :delay="1000"
                               type="is-dark">
                      <a v-on:click.stop @click="confirmDelete">
                        <b-icon icon="delete-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                  <view-guard :permission="'site-plan:update'" class="mr-1">
                    <b-tooltip label="Bulk Actions" class="mr-1" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="onMenuClicked">
                        <b-icon icon="dots-horizontal-circle-outline">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                  <context-search ref="contextMenu" :element-id="site._id">
                    <div style="width: 200px;" class="is-flex is-flex-direction-column">
                      <b-dropdown ref="dropDown" aria-role="list" class="is-pulled-right p-0">
                        <b-dropdown-item v-for="option in options" :key="option.name" aria-role="listitem"
                                         v-on:click.native.stop="onDropdownChange(option)">
                          <div class="media mt-1">
                            <a>
                              <b-icon class="media-left" :icon="option.icon"></b-icon>
                            </a>
                            <div class="media-content">
                              <h3>{{ option.name }}</h3>
                            </div>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </context-search>
                  <view-guard :permission="'report-provisioning:history'" class="mr-1 ml-3">
                    <b-tooltip label="View Plan History" :delay="1000" type="is-dark">
                      <a v-on:click.stop @click="onPlanHistoryClicked">
                        <b-icon icon="clipboard-text-clock">
                        </b-icon>
                      </a>
                    </b-tooltip>
                  </view-guard>
                </div>
              </div>
            </div>
          </div>
          <a class="card-header-icon">
            <b-icon
                :icon="isOpen ? 'chevron-down' : 'chevron-up'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <site-device-view v-for="device in site.listOfPlanDevices" :device="device" :site="site"
                            :real-device="realDevice(device)" :key="device._id" @onClickHistory="onClickHistory"
                            @onClickEditDevice="onClickEditDevice"
                            @onClickMaintenance="onClickMaintenance"></site-device-view>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ContextSearch from "@/components/context-search/context-search.vue";
import SiteDeviceView from "@/modules/projects-module/components/project-sites/site-device-view.vue";
import {getDate, TaskType} from "@/utils/utils";
import AddSiteDevices from "@/modules/projects-module/components/add-site-devices/add-site-devices.vue";
import {mapActions, mapGetters} from "vuex";
import UserAvatar from "@/components/avatar/avatar.vue";
import EditSitePlan from "@/modules/projects-module/components/edt-site-plan/edit-site-plan.vue";
import {getDeviceCountString} from "@/utils/helper";
import ViewGuard from "@/components/view-guard/view-guard.vue";

export default {
  name: "project-site-view",
  components: {ViewGuard, EditSitePlan, UserAvatar, AddSiteDevices, ContextSearch, SiteDeviceView},
  props: {
    site: {
      type: Object
    }
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isSelected: false,
      options: [
        {name: 'Edit locations', icon: 'map-marker', action: 'location'},
        {name: 'Edit configuration', icon: 'cog', action: 'configuration'},
        {name: 'Edit instruction', icon: 'information', action: 'instruction'},
        {name: 'Edit todo list', icon: 'clipboard-list', action: 'todo'},
      ]
    }
  },
  methods: {
    ...mapActions({
      deleteSitePlan: 'projectsModule/deleteSitePlan',
      selectSite: 'projectsModule/selectSite',
      deselectSite: 'projectsModule/deselectSite',
    }),
    onSelectionChanged(value){
        console.log('On selection changed: ', value)
        if (value) {
          this.selectSite(this.site)
        } else {
          this.deselectSite(this.site)
        }

        console.log('Selected sites: ', this.selectedSites)
    },
    onMenuClicked(event) {
      if (this.$refs.contextMenu.visible && this.$refs.dropDown && this.$refs.dropDown.expanded) {
        this.$refs.contextMenu.hideContextMenu()
        this.$refs.dropDown.toggle()
      } else {
        this.$refs.contextMenu.showMenu(event, {})
        this.$refs.dropDown.toggle()
      }
    },
    onClick() {
      console.log('On action clicked')
    },
    onEditSitePlan() {
      this.$refs.editSitePlan.show(true)
    },
    onMoveSitePlan() {
      this.$emit('onMoveSite', {site: this.site})
    },
    onAddDevices() {
      this.$refs.addDevices.show(true)
    },
    async onDropdownChange(value) {
      console.log('On dropdown change:', value)
      this.$emit('onSiteBulkAction', {action: value.action, site: this.site})
    },
    countDevices() {
      return getDeviceCountString(this.site.listOfPlanDevices)
    },
    onClickHistory(device) {
      console.log('On history click:', device)
      this.$emit('onHistoryClicked', device)
    },
    onPlanHistoryClicked() {
      this.$emit('onHistoryClicked', this.site)
    },
    onClickEditDevice(device) {
      device.siteName = this.site.siteName
      device.siteLocation = this.site.siteLocation
      device.color = this.planStatusColor(this.site.status)
      this.$emit('onEditDevice', device)
    },
    onClickMaintenance(device) {
      device.siteName = this.site.siteName
      device.siteLocation = this.site.siteLocation
      device.color = this.planStatusColor(this.site.status)
      this.$emit('onMaintenanceDevice', device)
    },
    planStatusColor(name) {
      if (this.$store.getters.getSettings) {
        let status = this.$store.getters.getSettings.planStatuses.find(status => status.name === name)
        if (status)
          return status.color
      }

      return '#ffffff'
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Are you sure you want to delete Site Plan?',
        onConfirm: () => this.delete()
      })
    },
    async delete() {
      console.log('Call delete site plan', this.site._id)

      this.isLoading = true
      let response = await this.deleteSitePlan(this.site._id)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {
        this.showToast('Project Site Plan successfully deleted', 'is-success')
      }
      this.isLoading = false

    },
    getUser(userId) {
      console.log('Get user id: ', userId)
      return this.$store.getters.getUserById(userId)
    },
    getDate(taskType) {
      let taskDate;
      if (this.site.listOfPlanDevices && this.site.listOfPlanDevices.length > 0) {
        this.site.listOfPlanDevices.forEach(device => {
          if (device.tasks && device.tasks.length > 0) {
            let task = device.tasks.find(task => task.taskType === taskType)
            if (task && task.date) {
              taskDate = getDate(new Date(task.date))
            }
          }
        })
      }
      console.log('Task date', taskDate)
      return taskDate
    },
    realDevice(device) {
      if (device && this.sitesAndDevices && this.sitesAndDevices.length > 0) {
        let devices = this.sitesAndDevices.map(siteDev => siteDev.devices)
        if (devices && devices.length > 0) {
          let devs = devices.flat()
          let index = devs.findIndex(dev => dev._id === device.deviceId)
          if (index !== -1) {
            return devs[index]
          }
        }
      }
      return null
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
  },
  computed: {
    ...mapGetters({
      selectedProject: 'projectsModule/getSelectedProject',
      selectedSites: 'projectsModule/getSelectedSites',
      selectedDevices: 'projectsModule/getSelectedDevices'
    }),
    TaskType() {
      return TaskType
    },
    sitesAndDevices() {
      if (this.selectedProject) {
        return this.selectedProject.sitesAndDevices
      }
      return []
    },
    installationCrew() {
      let installationCrew;
      if (this.site.listOfPlanDevices && this.site.listOfPlanDevices.length > 0) {
        this.site.listOfPlanDevices.forEach(device => {
          if (device.tasks && device.tasks.length > 0) {
            let task = device.tasks.find(task => task.taskType === TaskType.INSTALLATION)
            if (task && task.crew && task.crew.length > 0) {
              installationCrew = task.crew
            }
          }
        })
      }
      return installationCrew
    },
    decommissionCrew() {
      let decommissionCrew;
      if (this.site.listOfPlanDevices && this.site.listOfPlanDevices.length > 0) {
        this.site.listOfPlanDevices.forEach(device => {
          if (device.tasks && device.tasks.length > 0) {
            let task = device.tasks.find(task => task.taskType === TaskType.DECOMMISSION)
            if (task && task.crew && task.crew.length > 0) {
              decommissionCrew = task.crew
            }
          }
        })
      }
      return decommissionCrew
    }
  },
  watch: {
    selectedSites() {
      this.isSelected = this.selectedSites.findIndex(site => site._id === this.site._id) !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%)
}

.status-indicator {
  border-radius: 4px;
  background: black;
  width: 4px;
  height: 48px;
}
</style>