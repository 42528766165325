export default {
    setSelectedProcessingProject(state, project) {
        state.selectedProcessingProject = project
    },
    setQueryData(state, queryData) {
        state.searchQueryData = queryData
    },
    setSelectedVideoCalibration(state, calibrations) {
        state.selectedVideoCalibrations = calibrations
    },
    selectCalibration(state, calibration) {
        state.selectedCalibration = calibration
    },
    setSelectedProcessingDevice(state, processingDevice) {
        state.selectedProcessingDevice = processingDevice
    },
    replaceVideoCalibrations(state, videoCalibrations) {
        if (state.selectedProcessingProject) {
            let deviceIndex = state.selectedProcessingProject.devices.findIndex(device => state.selectedProcessingDevice._id === device._id)
            if (deviceIndex !== -1) {
                state.selectedProcessingProject.devices[deviceIndex].videoCalibrations = videoCalibrations
                state.selectedProcessingDevice = state.selectedProcessingProject.devices[deviceIndex]
                state.selectedVideoCalibrations = state.selectedProcessingProject.devices[deviceIndex].videoCalibrations
            }
        }
    },
    updateDeviceTasks(state, deviceData) {
        if (state.selectedProcessingProject) {
            let deviceIndex = state.selectedProcessingProject.devices.findIndex(device => deviceData.dataDeviceId === device._id)
            if (deviceIndex !== -1) {
                state.selectedProcessingProject.devices[deviceIndex].tasks = deviceData.tasks
            }
        }
    },
    setDpLegendSelection(state, selection) {
        state.dpLegendSelection = selection
    },
    mergeInitialValidationData(state, data){
        if (state.selectedProcessingProject && data.devices && data.devices.length > 0) {
            let devices =JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            data.devices.forEach(deviceData => {
                let deviceIndex = devices.findIndex(device =>  device._id === deviceData._id)
                if (deviceIndex !== -1) {
                    devices[deviceIndex].initialValidationData = deviceData
                    devices[deviceIndex].refresh = false
                }
            })

            state.selectedProcessingProject.devices = devices
            console.log("Merged devices: ", state.selectedProcessingProject.devices)
        }
    },

    replaceValidationData(state, data){
        if (state.selectedProcessingProject && data && data._id) {
            let devices =JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            let deviceIndex = devices.findIndex(device =>  device._id === data._id)
            if (deviceIndex !== -1) {
                devices[deviceIndex].initialValidationData = data
            }

            state.selectedProcessingProject.devices = devices
            console.log("Replaced devices: ", state.selectedProcessingProject.devices)
        }
    },

    replaceVideoPathData(state, data){
        if (state.selectedProcessingProject && data && data._id) {
            let devices =JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            let deviceIndex = devices.findIndex(device =>  device._id === data._id)
            if (deviceIndex !== -1) {
                devices[deviceIndex].tasks = data.tasks
            }

            state.selectedProcessingProject.devices = devices
            console.log("Replaced devices: ", state.selectedProcessingProject.devices)
        }
    },

    replaceVideoPathTasks(state, data){
        if (state.selectedProcessingProject && state.selectedProcessingProject.devices && state.selectedProcessingProject.devices.length > 0) {
            let devices =JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            data.forEach(deviceData => {
                let deviceIndex = devices.findIndex(device =>  device._id === deviceData.dataDeviceId)
                if (deviceIndex !== -1) {
                    devices[deviceIndex].tasks = deviceData.tasks
                    devices[deviceIndex].videoPathRefresh = false
                }
            })

            state.selectedProcessingProject.devices = devices
            console.log("Merged devices Video Path: ", state.selectedProcessingProject.devices)
        }
    },

    setRefreshForDataDevice(state, data){
        console.log('Find device for refresh: 1', data)
        if (state.selectedProcessingProject && state.selectedProcessingProject.devices && state.selectedProcessingProject.devices.length > 0) {
            console.log('Find device for refresh: 2')
            let devices = JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            let deviceIndex = devices.findIndex(device => device._id === data.id)
            if (deviceIndex !== -1) {
                console.log('Find device for refresh: ', deviceIndex)
                devices[deviceIndex].refresh = data.refresh
            }

            state.selectedProcessingProject.devices = devices
        }
    },

    setRefreshForDataDeviceStepOne(state, data){
        console.log('Find device for refresh step one: 1', data)
        if (state.selectedProcessingProject && state.selectedProcessingProject.devices && state.selectedProcessingProject.devices.length > 0) {
            console.log('Find device for refresh step one: 2')
            let devices = JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            let deviceIndex = devices.findIndex(device => device._id === data.id)
            if (deviceIndex !== -1) {
                console.log('Find device for refresh step one: ', deviceIndex)
                devices[deviceIndex].videoPathRefresh = data.refresh
            }

            state.selectedProcessingProject.devices = devices
        }
    },

    setRefreshingDevices(state, deviceRefresh){
        if (state.selectedProcessingProject && state.selectedProcessingProject.devices && state.selectedProcessingProject.devices.length > 0) {
            let devices =JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            deviceRefresh.forEach(dr => {
                let deviceIndex = devices.findIndex(device =>  device._id === dr.id)
                if (deviceIndex !== -1) {
                    devices[deviceIndex].refresh = dr.refresh
                }
            })

            state.selectedProcessingProject.devices = devices
        }
    },
    setVideoPathRefreshingDevices(state, deviceRefresh){
        if (state.selectedProcessingProject && state.selectedProcessingProject.devices && state.selectedProcessingProject.devices.length > 0) {
            let devices =JSON.parse(JSON.stringify(state.selectedProcessingProject.devices))
            deviceRefresh.forEach(dr => {
                let deviceIndex = devices.findIndex(device =>  device._id === dr.id)
                if (deviceIndex !== -1) {
                    devices[deviceIndex].videoPathRefresh = dr.refresh
                }
            })

            state.selectedProcessingProject.devices = devices
        }
    },
}
