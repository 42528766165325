<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="false"
        :is-full-page="true"
    ></b-loading>
    <div class="p-5" style="padding-bottom: 250px !important; ">
      <div class="box">
        <div class="columns">
          <div class="column is-3 ml-2">
            <div class="is-size-7 has-text-grey-light has-text-weight-normal">Site name</div>
            <div class="has-text-black has-text-weight-bold">{{ this.selectedDevice.siteName }}</div>
          </div>
          <div class="column is-3 m-0">
            <div class="is-size-7 has-text-grey-light has-text-weight-normal">Device</div>
            <div class="has-text-black has-text-weight-bold">{{ this.selectedDevice.name }}</div>
          </div>
          <div class="column m-0">
            <div class="is-size-7 has-text-grey-light has-text-weight-normal">Version</div>
            <b-taglist attached>
              <b-tag type="is-dark">Data Version</b-tag>
              <b-tag type="is-info">{{ validationChart.version }}</b-tag>
            </b-taglist>
          </div>
<!--          <div class="column is-flex">
            <b-taglist attached class="ml-auto">
              <b-tag type="is-dark">Data Version</b-tag>
              <b-tag type="is-info">{{ validationChart.version }}</b-tag>
            </b-taglist>
          </div>-->
          <div class="column is-flex" style="max-width: 260px; width: 260px" v-if="dataType === 'initial'">
            <b-button type="is-primary ml-auto" icon-left="play-circle" @click="onApprove">Approve and Continue</b-button>
          </div>
        </div>
      </div>
      <div class="box">
        <auto-validation-speed-chart
            v-if="validationChart"
            :speed-chart-data="validationChart.vehSpeedDistChart"></auto-validation-speed-chart>
      </div>
      <div class="box">
        <auto-validation-speed-percentile-chart v-if="validationChart"
                                                :speed-chart-data="validationChart.vehSpeedDistChart"></auto-validation-speed-percentile-chart>
      </div>
      <div class="box mt-6 mb-3 is-flex" v-if="dataType === 'initial'">
        <div class="is-size-5 has-text-weight-semibold">
          Auto validation data
        </div>
        <div class="ml-auto is-flex">
          <b-tooltip label="Up to +-5%" class="mr-1" :delay="1000" type="is-dark">
            <b-tag type="is-info" style="background: #18AA7E">Acceptable</b-tag>
          </b-tooltip>
          <b-tooltip label="Up to +-10%" class="mr-1" :delay="1000" type="is-dark">
            <b-tag type="is-info" style="background: #EEC832">&nbsp; &nbsp;Critical&nbsp; &nbsp;</b-tag>
          </b-tooltip>
          <b-tooltip label="More then +-10%" :delay="1000" type="is-dark">
            <b-tag type="is-info" style="background: #FF2B0F">Incorrect</b-tag>
          </b-tooltip>
        </div>
      </div>
      <div v-if="fakeData && dataType === 'initial'">
        <validation-data-table v-for="data in getDataList()" :key="Object.keys(data)[0]"
                               :validation-count="data"></validation-data-table>
      </div>
      <div v-else-if="dataType === 'full'">
          <final-vehicle-volume-chart></final-vehicle-volume-chart>
      </div>

    </div>
  </div>
</template>

<script>
import AutoValidationSpeedPercentileChart
  from "@/modules/data-processing-module/components/auto-validation-speed-percentile-chart/auto-validation-speed-percentile-chart.vue";
import AutoValidationSpeedChart
  from "@/modules/data-processing-module/components/auto-validation-speed-chart/auto-validation-speed-chart.vue";
import ValidationDataTable
  from "@/modules/data-processing-module/components/validation-data-table/validation-data-table.vue";
import {mapActions, mapGetters} from "vuex";
import FinalVehicleVolumeChart
  from "@/modules/data-processing-module/components/final-vehicle-volume-chart/final-vehicle-volume-chart.vue";

export default {
  name: "auto-validation-review",
  components: {
    FinalVehicleVolumeChart,
    ValidationDataTable, AutoValidationSpeedChart, AutoValidationSpeedPercentileChart},
  props: {
    projectId: {
      type: String
    },
    dataDeviceId: {
      type: String
    },
    dataType: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    console.log('Selected Device: ', this.selectedDevice)
    //console.log('Entries: ', Object.entries(this.fakeData))
    console.log('Project id: ', this.projectId)
    console.log('DDID id: ', this.dataDeviceId)
    console.log('DData Type: ', this.dataType)
  },
  methods: {
    ...mapActions({
      runFullValidation: 'dataProcessingModule/runFullValidation',
      runPedestrianProcessing: 'dataProcessingModule/runPedestrianProcessing',
    }),
    getDataList() {
      let list = []
      if (this.fakeData) {
        Object.keys(this.fakeData).forEach(key => {
          let obj = {};
          obj[key] = this.fakeData[key];
          list.push(obj)
        })
      }
      console.log('List: ', list)
      return list
    },
    onDecline() {
      console.log('On decline')
    },
    async onApprove() {
      console.log('Run initial validation: ', this.selectedDevice)
      this.isLoading = true
      let payload = {
        dataDeviceId: this.selectedDevice._id,
        selectedProject: this.selectedProcessingProject.project
      }
      console.log('Payload: ', payload)

      let response = await this.runPedestrianProcessing(payload)

      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
        this.isLoading = false
      } else {
        this.showToast('Pedestrian Processing successfully started', 'is-success')
        this.isLoading = false
        setTimeout(()=>{
          this.$router.back()
        }, 1000)
      }
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed: {
    ...mapGetters({
      selectedProcessingProject: 'dataProcessingModule/getSelectedProcessingProject',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
    validationChart() {
      if (this.selectedDevice && this.selectedDevice.initialValidationData && this.selectedDevice.initialValidationData.validationChart && this.dataType === 'initial') {
        return this.selectedDevice.initialValidationData.validationChart
      } else if (this.selectedDevice && this.selectedDevice.initialValidationData && this.selectedDevice.initialValidationData.validationChart && this.dataType === 'full') {
        return this.selectedDevice.initialValidationData.fullChart
      }

      return null
    },
    fakeData() {
      let fakeData = {
        '2024-10-15T08:30:00': {
          '*': {
            'Southbound': {
              userClass: ['light', 'medium'],
              ai_count: [46, 2],
              manual: [47, 0]
            },
            'Eastbound': {
              userClass: ['light', 'medium'],
              ai_count: [60, 1],
              manual: [64, 2]
            }
          },
          'Stage 1': {
            'Southbound': {
              userClass: ['light', 'medium'],
              ai_count: [26, 2],
              manual: [22, 1]
            },
            'Eastbound': {
              userClass: ['light', 'medium'],
              ai_count: [39, 1],
              manual: [39, 1]
            }
          }
        },
        '2024-10-15T08:45:00': {
          '*': {
            'Southbound': {
              userClass: ['light vehicle', 'medium'],
              ai_count: [46, 2],
              manual: [47, 0]
            },
            'Eastbound': {
              userClass: ['light vehicle', 'medium'],
              ai_count: [56, 1],
              manual: [64, 2]
            }
          },
          'Stage 1': {
            'Southbound': {
              userClass: ['light', 'medium'],
              ai_count: [26, 2],
              manual: [22, 1]
            },
            'Eastbound': {
              userClass: ['light', 'medium'],
              ai_count: [65, 1],
              manual: [61, 1]
            }
          }
        }
      }

      let inputData = {
        '*': {
          'Southbound': {
            '2024-10-15T08:30:00': {
              userClass: ['light', 'medium'],
              ai_count: [46, 2],
              manual: [47, 0]
            },
            '2024-10-15T08:45:00': {
              userClass: ['light', 'medium'],
              ai_count: [60, 1],
              manual: [64, 2]
            }
          },
          'Eastbound': {
            '2024-10-15T08:30:00': {
              userClass: ['light', 'medium'],
              ai_count: [66, 67],
              manual: [56, 58]
            },
            '2024-10-15T08:45:00': {
              userClass: ['light', 'medium'],
              ai_count: [45, 49],
              manual: [32, 37]
            }
          }
        },
        'Stage 1': {
          'Southbound': {
            '2024-10-15T08:30:00': {
              userClass: ['light', 'medium'],
              ai_count: [48, 15],
              manual: [32, 14]
            },
            '2024-10-15T08:45:00': {
              userClass: ['light', 'medium'],
              ai_count: [80, 78],
              manual: [34, 37]
            }
          },
        }
      }

      let newObj = {}


      if (this.selectedDevice && this.selectedDevice.initialValidationData && this.selectedDevice.initialValidationData.validationChart && this.selectedDevice.initialValidationData.validationChart.vehValidationCount && this.dataType === 'initial') {

        let inputData = this.selectedDevice.initialValidationData.validationChart.vehValidationCount
        let stages = Object.keys(inputData)
        if (stages && stages.length > 0) {
          stages.forEach(stage => {
            let directions = Object.keys(inputData[stage])
            if (directions && directions.length > 0) {
              directions.forEach(direction => {
                let dates = Object.keys(inputData[stage][direction])
                if (dates && dates.length > 0) {
                  dates.forEach(date => {
                    if(!newObj[date]){
                      newObj[date] = {}
                    }
                    if(!newObj[date][stage]){
                      newObj[date][stage] = {}
                    }
                    if(!newObj[date][stage][direction]){
                      newObj[date][stage][direction] = {}
                    }
                    newObj[date][stage][direction] = inputData[stage][direction][date]
                  })
                }
              })
            }
          })
        }
        console.log('New object', newObj)
        return newObj
      }

      return null
    }
  }
}
</script>

<style scoped>

</style>