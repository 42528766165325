const moment = require("moment-timezone");

let appName = 'Realite Admin'

let gMapStyles = [
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]

let risks = {
    'Severe': 1,
    'Moderate': 2,
    'Low': 3,
}

let Role = {
    ALL: 'All',
    ADMIN: 'Administrator',
    TRAFFIC_ENG: 'Traffic Engineer'
}

let Apps = {
    SAFETY: 'safety',
    PAX: 'pax'
}

let DeviceType = {
    CAMERA: 'CAMERA',
    ATC: 'ATC',
    RADAR: 'RADAR'
}

let TemplateType = {
    TODO: 'TODO',
    SETTINGS_PROFILE: 'SETTINGS_PROFILE'
}

let TaskType = {
    INSTALLATION: 'INSTALLATION',
    DECOMMISSION: 'DECOMMISSION',
    MAINTENANCE: 'MAINTENANCE'
}


let DeviceStatus = {
    CREATED: {name: "CREATED", color: "#7D7D7D"},
    INSTALLATION: {name: "INSTALLATION", color: "#7D73F7"},
    READY: {name: "READY", color: "#18AA7E"},
    DECOMMISSION: {name: "DECOMMISSION", color: "#FF00F5"},
    MALFUNCTION: {name: "MALFUNCTION", color: "#F36A6A"},
    OFF: {name: "OFF", color: "#FF9A01"}
}

let VideoCalibrationCategory = {
    STAGE_POLYGON: 'stagePoly',
    DWELL_POLYGON: 'dwellPoly',
    DIRECTION_POLYGON: 'directionPoly',
    APPROACH: 'approach',
    SPEED_LINE: 'speedLine',
    EVENT_LINE: 'eventLine',
}

function calculateSimpleRisk(risksArray) {

    console.log('Calculate simple risk: ', risksArray)

    if (risksArray && risksArray.length > 0) {
        let ratingsMap = new Map();

        //Sum values
        risksArray.forEach(rating => {
            if (ratingsMap.has(`${rating}`)) {
                ratingsMap.set(`${rating}`, ratingsMap.get(`${rating}`) + 1)
            } else {
                ratingsMap.set(`${rating}`, 1)
            }
        })

        console.log('Calculate simple risk map', ratingsMap)

        const max = [...ratingsMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a)

        console.log('Calculate simple risk max', max)
        if (max.length > 0) {
            return capitalizeFirstLetter(max[0])
        }
    }

    return '-'
}

function calculateRiskSumFromWeeklyReport(weeklyReport) {

    if (weeklyReport && weeklyReport.length > 0) {
        let ratings = weeklyReport.map(report => {
            return report.severityRating
        })
        let ratingsMap = new Map();
        ratingsMap.set('low', 0)
        ratingsMap.set('moderate', 0)
        ratingsMap.set('severe', 0)

        //Sum values
        if (ratings && ratings.length > 0) {
            ratings.forEach(rating => {
                if (rating && rating.length > 1) {
                    ratingsMap.set(`${rating[0]}`, ratingsMap.get(`${rating[0]}`) + rating[1])
                }
            })

            console.log('Weekly severity ratings calc', ratingsMap)

            const max = [...ratingsMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a)

            console.log('Weekly severity ratings max', max)
            if (max.length > 0) {
                return capitalizeFirstLetter(max[0])
            }
        }
    }

    return '-'
}

function calculateToDcaFromWeeklyReport(weeklyReport) {
    if (weeklyReport && weeklyReport.length > 0) {
        let topDca = weeklyReport.map(report => {
            return report.topDCA
        })
        console.log('Top dca:', topDca)
        let topDcaMap = new Map();

        if (topDca && topDca.length > 0) {
            //Sum values
            topDca.forEach(tpDca => {
                if (tpDca && tpDca.length > 1) {
                    if (topDcaMap.has(`${tpDca[0]}`)) {
                        topDcaMap.set(`${tpDca[0]}`, topDcaMap.get(`${tpDca[0]}`) + tpDca[1])
                    } else {
                        topDcaMap.set(`${tpDca[0]}`, tpDca[1])
                    }
                }

            })

            console.log('Top dca map:', topDcaMap)
            const max = [...topDcaMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a)

            console.log('Top DCA max', max)
            if (max.length > 0) {
                return max[0]
            }
        }
    }
    return '-'
}

function capitalizeFirstLetter(string) {
    let lower = string.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
}

/*function splitAndCapitalizeCamelCase(str) {
    if(str){
        let result = str.replace(/([A-Z])/g, ' $1')
            .replace(/^./, function (str) {
                return str.toUpperCase();
            })
        return result
    }

    return ''

}*/

function getRiskRatingClass(property) {
    return {
        'severe': property === 'Severe',
        'high': property === 'Moderate',
        'low': property === 'Low'
    }
}

function getSeverityRatingScore(property) {
    if (property === 'severe') {
        return 90
    } else if (property === 'moderate') {
        return 40
    } else {
        return 10
    }
}

function getMarkerClass(isSelected) {
    console.log('Get marker class item: ', isSelected)
    return isSelected ? 'oval-marker oval-marker-selected' : 'oval-marker'
}

function getMarkerIcon(deviceType) {
    if (deviceType === DeviceType.CAMERA) {
        return 'cctv'
    } else if (deviceType === DeviceType.ATC) {
        return 'arrow-split-vertical'
    } else if (deviceType === DeviceType.RADAR) {
        return 'radar'
    }
}

function getDirectionClass(style, isSelected) {
    let styleClass = 'camera-direction-on-dark'
    if (style.includes('mapbox://styles/mapbox/streets-v11') || style.includes('mapbox://styles/mapbox/light-v11')) {
        styleClass = 'camera-direction-on-light'
    }

    if (isSelected) {
        styleClass += ' camera-direction-selected'
    }

    return styleClass
}


function startEndFromTimeZoneToIso(dateString, timeZone) {
    let m = moment.tz(dateString, timeZone);
    let start = m.clone().startOf('day').utc();
    let end = m.clone().endOf('day').utc();

    return {start: start.utc().format(), end: end.utc().format()}
}

function dateToISOString(date, endOfTheDay) {
    //return date ? date.toISOString() : null

    if (date) {
        let time = moment.tz(date.toISOString(), "Australia/Melbourne");
        time.set({hour: 0, minute: 0, second: 0, millisecond: 0})

        return time.utc().format()
    }

    return null
}

function dateToISOStringEndOfDay(date) {
    //return date ? date.toISOString() : null

    if (date) {
        let time = moment.tz(date.toISOString(), "Australia/Melbourne");
        time.set({hour: 23, minute: 59, second: 59, millisecond: 999})

        return time.utc().format()
    }

    return null
}

/*
function timeToString(date) {
    if (date) {
        let time = moment(date.toISOString());
        return time.format("hh:mm a")
    }

    return null
}
*/

function timeToString(date) {
    if (date) {
        let time = moment(date.toISOString());
        return time.format("HH:mm")
    }

    return null
}

function getInputPeriod(period) {
    if (period && period.dateRange && period.dateRange.length > 1) {
        let range = []
        range.push(getDateObjectFromMelbourneUtcToLocal(period.dateRange[0]))
        range.push(getDateObjectFromMelbourneUtcToLocal(period.dateRange[1]))

        let timeRanges = []
        if (period.timeRanges && period.timeRanges.length > 0) {
            period.timeRanges.forEach(timeRange => {
                if (timeRange && timeRange.startTime && timeRange.endTime) {
                    let startM = moment("20/09/2018 " + timeRange.startTime, "DD/MM/YYYY h:mm a")
                    let endM = moment("20/09/2018 " + timeRange.endTime, "DD/MM/YYYY h:mm a")
                    timeRanges.push(
                        {startTime: startM.toDate(), endTime: endM.toDate()}
                    )
                }
            })
        }

        return {
            dateRange: range,
            timeRanges: timeRanges
        }
    } else {
        //return empty
        return {dateRange: [], timeRanges: [{startTime: null, endTime: null}]}
    }
}

function convertPeriodToDateIsoStrings(period) {
    let convertedPeriod
    if (period) {
        let timeRanges = []
        if (period.timeRanges && period.timeRanges.length > 0) {
            period.timeRanges.forEach(timeRange => {
                if (timeRange.startTime && timeRange.endTime) {
                    timeRanges.push({
                        startTime: timeToString(timeRange.startTime),
                        endTime: timeToString(timeRange.endTime)
                    })
                }
            })
        }
        convertedPeriod = {
            dateRange: period.dateRange && period.dateRange.length > 1 ? [dateToISOString(period.dateRange[0]), dateToISOStringEndOfDay(period.dateRange[1])] : null,
            timeRanges: timeRanges
        }
    }
    return convertedPeriod
}



function getDate(date, format) {
    //let utcDate = moment(date).utc()
    //return moment(utcDate).add(10, 'hours').format('DD-MM-YYYY')

    let dateUtc = moment.utc(date);
    //let localDate = moment(dateUtc).local();
    let localDate = moment.tz(dateUtc, "Australia/Melbourne");

    if (format) {
        return localDate.format(format)
    } else {
        return localDate.format('DD-MM-YYYY')
    }
}

function getDateInTimeZone(date, timezone) {

    let dateUtc = moment.utc(date);
    let zone = timezone ? timezone : "Australia/Melbourne"
    let localDate = moment.tz(dateUtc, zone);


    return localDate.format('DD-MM-YYYY')
}

function getDateRange(range) {
    let dateStartUtc = moment.utc(range[0]);
    let dateEndUtc = moment.utc(range[1]);
    let localDateStart = moment.tz(dateStartUtc, "Australia/Melbourne");
    let localDateEnd = moment.tz(dateEndUtc, "Australia/Melbourne");

    return localDateStart.format('DD-MM-YYYY') + ' - ' + localDateEnd.format('DD-MM-YYYY')
}

function parseTimeRanges(timeRanges) {
    if (timeRanges && timeRanges.length > 0) {
        let timeArray = []
        timeRanges.forEach(timeRange => {
            if (timeRange) {
                let str = timeRange.startTime + ' - ' + timeRange.endTime
                timeArray.push(str)
            }
        })
        return timeArray
    }

    return []
}

function getDateObjectFromMelbourneUtcToLocal(date) {
    let dateUtc = moment(date).utc();
    console.log('Date utc', dateUtc)

    let melbourneTime = moment.tz(dateUtc, "Australia/Melbourne");

    return new Date(melbourneTime.year(), melbourneTime.month(), melbourneTime.date(), 0, 0, 0, 0)
}

function getTime(date, timezone) {
    //let utcTime = moment(date).utc()
    //return moment(utcTime).add(10, 'hours').format('HH:mm:ss')

    let dateUtc = moment.utc(date);
    //let localTime = moment(dateUtc).local();
    let zone = timezone ? timezone : "Australia/Melbourne"
    let localTime = moment.tz(dateUtc, zone);

    return localTime.format('HH:mm:ss')
}

function getDateTime(date, timezone) {
    let dateUtc = moment.utc(date);
    //let localTime = moment(dateUtc).local();
    let zone = timezone ? timezone : "Australia/Melbourne"
    let localTime = moment.tz(dateUtc, zone);

    return localTime.format('DD.MM.YYYY HH:mm:ss')
}

function getVideoTimeFromLocal(dateString) {
    console.log('Date string: ', dateString)
    if(dateString){
        let str = dateString.slice(0, dateString.length-5);
        let dateMoment = moment(str).utc(true)

        return dateMoment.format('DD.MM.YYYY HH:mm:ss')
    }
    return '--:--'
}

function getVideoTimeFromLocalToIsoString(dateString) {
    console.log('Date string: ', dateString)
    if(dateString){
        let str = dateString.slice(0, dateString.length-6);

        return str + 'Z'
    }
    return '--:--'
}

function getPickerTimeToTimeZone(date, timezone) {
    let dateUtc = moment(date);
    let tmz = moment.tz(timezone).format('Z')
    let formattedString = dateUtc.format('YYYY-MM-DDTHH:mm:ss')+tmz
    return moment(formattedString).toISOString()
}

function formatTimeInTimeZone(date) {
    let utcOffset = moment.parseZone(date).utcOffset();
    let dateFor = moment.utc(date).utcOffset(utcOffset);

    return dateFor.format('DD.MM.YYYY HH:mm:ss');
}

function formatUtcTime(date) {
    let dateFor = moment.utc(date)
    return dateFor.format('DD.MM.YYYY HH:mm:ss');
}

function getDateTimeInTimeZone(date) {
    let utcOffset = moment.parseZone(date).utcOffset();
    let localTime = moment.utc(date).utcOffset(utcOffset);

    let formatted = localTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
    return new Date(formatted)
}

function getDeviceSvg(rotation, device, isSelected) {
    console.log('GetDeviceSvg device: ', device)
    let color = device.color ? device.color : DeviceStatus[device.currentStatus.name].color
    let stroke = isSelected ? 3 : 0
    if (device.type === DeviceType.CAMERA) {
        return `<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g transform="rotate(${rotation})"
                transform-origin="center">
                <path d="M50 52L16 7.38403e-07L78.5 -7.15256e-07L50 52Z" fill="url(#paint0_linear_1477_24)"/>
                </g>
                <path d="M63 51C63 57.6274 57.6274 63 51 63C44.3726 63 39 57.6274 39 51C39 44.3726 44.3726 39 51 39C57.6274 39 63 44.3726 63 51Z" fill="${color}" stroke-width="${stroke}" stroke="black" stroke-location="outside"/>
                <path d="M45.5225 50.0224L47.0225 52.6249L45.125 55.0099L42.5 50.4649L45.5225 50.0224ZM53.75 54.4999V52.4674C54.41 52.1749 54.875 51.5224 54.875 50.7499C54.875 50.3224 54.725 49.9249 54.4775 49.6249L55.955 48.7624C56.7125 48.3199 56.975 47.3524 56.5325 46.5949L55.4975 44.7949C55.055 44.0374 54.0875 43.7749 53.33 44.2099L47.2325 47.7499C46.52 48.1474 46.2725 49.0624 46.685 49.7824L47.81 51.7324C48.2225 52.4449 49.145 52.6924 49.8575 52.2799L51.2675 51.4699C51.455 51.9124 51.8075 52.2724 52.25 52.4674V54.4999C52.25 55.3249 52.925 55.9999 53.75 55.9999H57.5V54.4999H53.75Z" fill="white"/>
                <rect x="18" y="21" width="64" height="13" rx="6.5" fill="white"/>
                <text fill="black" fill-opacity="0.75" xml:space="preserve" style="white-space: pre" font-family="Roboto" text-anchor="middle" font-size="10" font-weight="600" letter-spacing="0em"><tspan x="50%" y="30.7265">${device.name}</tspan></text>
                <defs>
                <linearGradient id="paint0_linear_1477_24" x1="52.9914" y1="74.7967" x2="54.97" y2="1.04239" gradientUnits="userSpaceOnUse">
                <stop stop-color="black"/>
                <stop offset="0.0001" stop-color="black"/>
                <stop offset="1" stop-color="black" stop-opacity="0"/>
                </linearGradient>
                </defs>
                </svg>`


    } else if (device.type === DeviceType.RADAR) {
        return `<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="28" r="12" fill="${color}" stroke-width="${stroke}" stroke="black" stroke-location="outside"/>
                <path d="M33.3025 22.6975L32.245 23.755C33.325 24.8425 34 26.3425 34 28C34 29.5913 33.3679 31.1174 32.2426 32.2426C31.1174 33.3679 29.5913 34 28 34C26.4087 34 24.8826 33.3679 23.7574 32.2426C22.6321 31.1174 22 29.5913 22 28C22 24.94 24.2875 22.42 27.25 22.0525V23.5675C25.12 23.9275 23.5 25.7725 23.5 28C23.5 29.1935 23.9741 30.3381 24.818 31.182C25.6619 32.0259 26.8065 32.5 28 32.5C29.1935 32.5 30.3381 32.0259 31.182 31.182C32.0259 30.3381 32.5 29.1935 32.5 28C32.5 26.755 31.9975 25.63 31.18 24.82L30.1225 25.8775C30.6625 26.425 31 27.175 31 28C31 28.7956 30.6839 29.5587 30.1213 30.1213C29.5587 30.6839 28.7956 31 28 31C27.2044 31 26.4413 30.6839 25.8787 30.1213C25.3161 29.5587 25 28.7956 25 28C25 26.605 25.96 25.4425 27.25 25.105V26.71C26.8 26.9725 26.5 27.445 26.5 28C26.5 28.3978 26.658 28.7794 26.9393 29.0607C27.2206 29.342 27.6022 29.5 28 29.5C28.3978 29.5 28.7794 29.342 29.0607 29.0607C29.342 28.7794 29.5 28.3978 29.5 28C29.5 27.445 29.2 26.965 28.75 26.71V20.5H28C27.0151 20.5 26.0398 20.694 25.1299 21.0709C24.2199 21.4478 23.3931 22.0003 22.6967 22.6967C21.2902 24.1032 20.5 26.0109 20.5 28C20.5 29.9891 21.2902 31.8968 22.6967 33.3033C23.3931 33.9997 24.2199 34.5522 25.1299 34.9291C26.0398 35.306 27.0151 35.5 28 35.5C29.9891 35.5 31.8968 34.7098 33.3033 33.3033C34.7098 31.8968 35.5 29.9891 35.5 28C35.5 25.93 34.66 24.055 33.3025 22.6975Z" fill="white"/>
                <rect x="2" y="1" width="52" height="13" rx="6.5" fill="white"/>
                <text fill="black" fill-opacity="0.75" xml:space="preserve" style="white-space: pre" font-family="Roboto" text-anchor="middle" font-size="10" font-weight="600" letter-spacing="0em"><tspan x="50%" y="10.7265">${device.name}</tspan></text>
                </svg>`
    } else if (device.type === DeviceType.ATC) {
        return `<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="28" r="12" fill="${color}" stroke-width="${stroke}" stroke="black" stroke-location="outside"/>
                <path d="M32.5 31V28.75H30.25V35.5H28.75V20.5H30.25V27.25H32.5V25L35.5 28L32.5 31ZM20.5 28L23.5 31V28.75H25.75V35.5H27.25V20.5H25.75V27.25H23.5V25L20.5 28Z" fill="white"/>
                <rect x="2" width="52" height="13" rx="6.5" fill="white"/>
                <text fill="black" fill-opacity="0.75" xml:space="preserve" style="white-space: pre" font-family="Roboto" text-anchor="middle" font-size="10" font-weight="600" letter-spacing="0em"><tspan x="50%" y="9.7265">${device.name}</tspan></text>
                </svg>`
    }

}

function getRealDeviceSvg(device) {

    let color = device.color ? device.color : DeviceStatus[device.currentStatus.name].color

    if (device.type === DeviceType.CAMERA) {
        return `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 16L45.8564 24V40L32 48L18.1436 40V24L32 16Z" fill="${color}"/>
                <path d="M19.1436 24.5773L32 17.1547L44.8564 24.5773V39.4226L32 46.8453L19.1436 39.4226V24.5773Z" stroke="black" stroke-opacity="0.1" stroke-width="2"/>
                <path d="M27.0253 32.025L28.692 34.9167L26.5837 37.5667L23.667 32.5167L27.0253 32.025ZM36.167 37V34.7417C36.9003 34.4167 37.417 33.6917 37.417 32.8333C37.417 32.3583 37.2503 31.9167 36.9753 31.5833L38.617 30.625C39.4587 30.1333 39.7503 29.0583 39.2587 28.2167L38.1087 26.2167C37.617 25.375 36.542 25.0833 35.7003 25.5667L28.9253 29.5C28.1337 29.9417 27.8587 30.9583 28.317 31.7583L29.567 33.925C30.0253 34.7167 31.0503 34.9917 31.842 34.5333L33.4087 33.6333C33.617 34.125 34.0087 34.525 34.5003 34.7417V37C34.5003 37.9167 35.2503 38.6667 36.167 38.6667H40.3337V37H36.167Z" fill="white"/>
                <rect y="1" width="64" height="13" rx="6.5" fill="white"/>
                <text fill="black" fill-opacity="0.75" xml:space="preserve" style="white-space: pre" font-family="Roboto" text-anchor="middle" font-size="10" font-weight="700" letter-spacing="0em"><tspan x="50%" y="10.7265">${device.name}</tspan></text>
                </svg>`
    } else if (device.type === DeviceType.RADAR) {
        return `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 16L45.8564 24V40L32 48L18.1436 40V24L32 16Z" fill="${color}"/>
                <path d="M19.1436 24.5773L32 17.1547L44.8564 24.5773V39.4226L32 46.8453L19.1436 39.4226V24.5773Z" stroke="black" stroke-opacity="0.6" stroke-width="2"/>
                <path d="M37.3025 26.6975L36.245 27.755C37.325 28.8425 38 30.3425 38 32C38 33.5913 37.3679 35.1174 36.2426 36.2426C35.1174 37.3679 33.5913 38 32 38C30.4087 38 28.8826 37.3679 27.7574 36.2426C26.6321 35.1174 26 33.5913 26 32C26 28.94 28.2875 26.42 31.25 26.0525V27.5675C29.12 27.9275 27.5 29.7725 27.5 32C27.5 33.1935 27.9741 34.3381 28.818 35.182C29.6619 36.0259 30.8065 36.5 32 36.5C33.1935 36.5 34.3381 36.0259 35.182 35.182C36.0259 34.3381 36.5 33.1935 36.5 32C36.5 30.755 35.9975 29.63 35.18 28.82L34.1225 29.8775C34.6625 30.425 35 31.175 35 32C35 32.7956 34.6839 33.5587 34.1213 34.1213C33.5587 34.6839 32.7956 35 32 35C31.2044 35 30.4413 34.6839 29.8787 34.1213C29.3161 33.5587 29 32.7956 29 32C29 30.605 29.96 29.4425 31.25 29.105V30.71C30.8 30.9725 30.5 31.445 30.5 32C30.5 32.3978 30.658 32.7794 30.9393 33.0607C31.2206 33.342 31.6022 33.5 32 33.5C32.3978 33.5 32.7794 33.342 33.0607 33.0607C33.342 32.7794 33.5 32.3978 33.5 32C33.5 31.445 33.2 30.965 32.75 30.71V24.5H32C31.0151 24.5 30.0398 24.694 29.1299 25.0709C28.2199 25.4478 27.3931 26.0003 26.6967 26.6967C25.2902 28.1032 24.5 30.0109 24.5 32C24.5 33.9891 25.2902 35.8968 26.6967 37.3033C27.3931 37.9997 28.2199 38.5522 29.1299 38.9291C30.0398 39.306 31.0151 39.5 32 39.5C33.9891 39.5 35.8968 38.7098 37.3033 37.3033C38.7098 35.8968 39.5 33.9891 39.5 32C39.5 29.93 38.66 28.055 37.3025 26.6975Z" fill="white"/>
                <rect y="1" width="64" height="13" rx="6.5" fill="white"/>
                <text fill="black" fill-opacity="0.75" xml:space="preserve" style="white-space: pre" font-family="Roboto" text-anchor="middle" font-size="10" font-weight="700" letter-spacing="0em"><tspan x="50%" y="10.7265">${device.name}</tspan></text>
                </svg>`
    } else if (device.type === DeviceType.ATC) {
        return `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 16L45.8564 24V40L32 48L18.1436 40V24L32 16Z" fill="${color}"/>
                <path d="M19.1436 24.5773L32 17.1547L44.8564 24.5773V39.4226L32 46.8453L19.1436 39.4226V24.5773Z" stroke="black" stroke-opacity="0.6" stroke-width="2"/>
                <path d="M36.5 35V32.75H34.25V39.5H32.75V24.5H34.25V31.25H36.5V29L39.5 32L36.5 35ZM24.5 32L27.5 35V32.75H29.75V39.5H31.25V24.5H29.75V31.25H27.5V29L24.5 32Z" fill="white"/>
                <rect y="1" width="64" height="13" rx="6.5" fill="white"/>
                <text fill="black" fill-opacity="0.75" xml:space="preserve" style="white-space: pre" font-family="Roboto" text-anchor="middle" font-size="10" font-weight="700" letter-spacing="0em"><tspan x="50%" y="10.7265">${device.name}</tspan></text>
                </svg>`
    }


}

function getSiteSvg(color, siteName) {
    let siteColor = color ? color : 'black'
    return `<svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="42" cy="42" r="4" fill="${siteColor}" fill-opacity="0.5"/>
<circle cx="42" cy="42" r="28" fill="${siteColor}" fill-opacity="0.25"/>
<text fill="red" xml:space="preserve" style="white-space: pre" dominant-baseline="middle" text-anchor="middle" font-family="Roboto" font-size="10" font-weight="600" letter-spacing="0em"><tspan x="50%" y="8.7265">${siteName}</tspan></text>
</svg>`
}

function getSitePlanSvg(color, siteName, isSelected) {
    let siteColor = color ? color : 'black'
    let stroke = isSelected ? 2 : 0
    return `<svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="42" cy="42" r="4" fill="${siteColor}" fill-opacity="0.5"/>
<circle cx="42" cy="42" r="28" fill="${siteColor}" fill-opacity="0.25" stroke-width="${stroke}" stroke="#00000050"/>
<text fill="black" xml:space="preserve" style="white-space: pre;" dominant-baseline="middle" text-anchor="middle" font-family="Roboto" font-size="10" font-weight="600" letter-spacing="0em"><tspan x="50%" y="79.7265">${siteName}</tspan></text>
</svg>`
}

function getGmapPopupContent(devicePlan) {
    return `<div class="m-0 p-0" style="min-width: 250px; max-width: 320px; font: 12px/20px 'Barlow', sans-serif;">
    <div class="columns m-0 p-0 pt-2">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">${devicePlan.name}</div>
      </div>
      <div class="column m-0 p-0">
        <div class="ml-auto" style="
          background: ${devicePlan.color};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${devicePlan.status}</div>
      </div>
    </div>
        <div class="columns m-0 p-0 pt-3">
      <div class="column p-0 m-0">
        <div class="is-size-7">Site: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${devicePlan.siteName}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Device Type: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${devicePlan.type}</div>
      </div>
    </div>
  </div>`;
}

function getGmapPopupDeviceContent(device, statusColor, projectName) {

    let healthStatus = ''
    let projectNamePart = ''
    if (device.health && device.health.status) {
        healthStatus = `
           <div class="columns m-0 p-0 mt-2">
              <div class="column p-0 m-0">
                <div class="is-size-7">Connection Status</div>
              </div>
              <div class="column is-flex p-0 m-0">
                <div class="ml-auto" 
                style="${device.health && device.health.connectionStatus && (device.health.connectionStatus === 'connected' || device.health.connectionStatus === 'rebooted') ? 'color: green' : 'color: red'}">
                    ${device.health && device.health.connectionStatus ? device.health.connectionStatus : 'Unknown'}
                </div>
              </div>
            </div>
            <div class="columns m-0 p-0 mt-0">
              <div class="column p-0 m-0">
                <div class="is-size-7">Health Status @ ${getDateTime(device.health.createdAt)}</div>
              </div>
              <div class="column is-flex p-0 m-0" style="max-width: 48px">
                <div class="ml-auto oval" style="background: ${device.health.status}; margin-top: 6px"></div>
              </div>
           </div>`
    }

    if (projectName) {
        projectNamePart = `<div class="columns m-0 p-0">
      <div class="column p-0 m-0 is-3">
        <div class="is-size-7">Project: </div>
      </div>
      <div class="column is-flex p-0 m-0 is-9">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${projectName}</div>
      </div>
    </div>`
    }

    return `<div class="m-0 p-0" style="min-width: 350px; max-width: 350px; font: 12px/20px 'Barlow', sans-serif;">
    <div class="columns m-0 p-0 pt-2 pb-3">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">${device.name}</div>
      </div>
      <div class="column m-0 p-0">
        <div class="ml-auto" style="
          background: ${statusColor ? statusColor : device.color};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${device.currentStatus}</div>
      </div>
    </div>
    ${projectNamePart}
        <div class="columns m-0 p-0">
      <div class="column p-0 m-0 is-3">
        <div class="is-size-7">Site: </div>
      </div>
      <div class="column is-flex p-0 m-0 is-9">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.siteName}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Device Type: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.type}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Ext device id: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.extDeviceId}</div>
      </div>
    </div>
    ${healthStatus}
    <hr class="solid-thin mt-1 mb-3">
    <div class="columns m-0 p-0 pl-3 pr-3 pb-2 is-align-items-center">
      <div class="column p-0 m-0 is-flex is-flex-direction-column is-align-items-start">
        <div class="is-flex is-align-items-center">
          <span class="mdi mdi-battery-40 popup-icon"></span>
          <div class="is-size-6">${device.health && device.health.voltage ? device.health.voltage.toFixed(2) : '-'} V</div>
        </div>

      </div>
      <div class="column p-0 m-0 is-flex is-flex-direction-column is-align-items-center">
        <div class="is-flex is-align-items-center">
        <span class="mdi mdi-thermometer popup-icon"></span>
        <div class="is-size-6">${device.health && device.health.temperature ? device.health.temperature : '-'}°C</div>
        </div>
      </div>
      <div class="column p-0 m-0 is-flex is-flex-direction-column is-align-items-end">
        <div class="is-flex is-align-items-center">
        <span class="mdi mdi-water-percent popup-icon"></span>
        <div class="is-size-6">${device.health && device.health.humidity ? device.health.humidity : '-'}%</div>
        </div>
      </div>
    </div>
<!--    <b-button style="margin-top: 32px" type="is-primary" expanded>View details</b-button>-->
  </div>`;
}

function getGmapPopupDPContent(device, deviceStatusColor, projectName, additionalData) {

    let projectNamePart = ''

    if (projectName) {
        projectNamePart = `<div class="columns m-0 p-0">
      <div class="column p-0 m-0 is-3">
        <div class="is-size-7">Project: </div>
      </div>
      <div class="column is-flex p-0 m-0 is-9">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${projectName}</div>
      </div>
    </div>`
    }

    return `<div class="m-0 p-0" style="min-width: 350px; max-width: 350px; font: 12px/20px 'Barlow', sans-serif;">
    <div class="columns m-0 p-0 pt-2 pb-3">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">${device.name}</div>
      </div>
      <div class="column m-0 p-0">
        <div class="ml-auto" style="
          background: ${deviceStatusColor};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${device.currentStatus}</div>
      </div>
    </div>
    ${projectNamePart}
        <div class="columns m-0 p-0">
      <div class="column p-0 m-0 is-3">
        <div class="is-size-7">Site: </div>
      </div>
      <div class="column is-flex p-0 m-0 is-9">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.siteName}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Device Type: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.type}</div>
      </div>
    </div>
    <hr class="solid-thin mt-1 mb-3">
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Tasks Status: </div>
      </div>
      <div class="column is-flex p-0 m-0">
                <div class="ml-auto" style="
          background: ${additionalData.statusColor};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${additionalData.status}</div>
      </div>
    </div>
  </div>`;
}

function getGmapPopupQaContent(device, deviceStatusColor, projectName, additionalData) {

    let projectNamePart = ''

    if (projectName) {
        projectNamePart = `<div class="columns m-0 p-0">
      <div class="column p-0 m-0 is-3">
        <div class="is-size-7">Project: </div>
      </div>
      <div class="column is-flex p-0 m-0 is-9">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${projectName}</div>
      </div>
    </div>`
    }

    return `<div class="m-0 p-0" style="min-width: 350px; max-width: 350px; font: 12px/20px 'Barlow', sans-serif;">
    <div class="columns m-0 p-0 pt-2 pb-3">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">${device.name}</div>
      </div>
      <div class="column m-0 p-0">
        <div class="ml-auto" style="
          background: ${deviceStatusColor};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${device.currentStatus}</div>
      </div>
    </div>
    ${projectNamePart}
        <div class="columns m-0 p-0">
      <div class="column p-0 m-0 is-3">
        <div class="is-size-7">Site: </div>
      </div>
      <div class="column is-flex p-0 m-0 is-9">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.siteName}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Device Type: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${device.type}</div>
      </div>
    </div>
    <hr class="solid-thin mt-1 mb-3">
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">QA Status: </div>
      </div>
      <div class="column is-flex p-0 m-0">
                <div class="ml-auto" style="
          background: ${additionalData.statusColor};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${additionalData.status}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Vehicles Version: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${additionalData.vehicleDataVersion}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">Pedestrians Version: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${additionalData.pedestrianDataVersion}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">QA by: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7 has-text-weight-semibold">${additionalData.userName}</div>
      </div>
    </div>
    <div class="columns m-0 p-0">
      <div class="column p-0 m-0">
        <div class="is-size-7">QA at: </div>
      </div>
      <div class="column is-flex p-0 m-0">
        <div class="ml-auto is-size-7">${additionalData.createdAt}</div>
      </div>
    </div>
  </div>`;
}

function getGMapPopupSitePlanContent(site) {

    let devices = ``
    site.deviceNames.forEach(devName => {
        devices += `<div>${devName}</div>`
    })

    return `<div class="m-0 p-0" style="min-width: 250px; max-width: 320px; font: 12px/20px 'Barlow', sans-serif;">
    <div class="columns m-0 p-0 pt-2">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">${site.siteName}</div>
      </div>
      <div class="column m-0 p-0">
        <div class="ml-auto" style="
          background: ${site.color};
          color: white;
          height: 20px;
          width: fit-content;
          line-height: 1;
          border-radius: 4px;
          padding: 4px 8px 0 8px;
        ">${site.status}</div>
      </div>
    </div>
    <div>Devices: </div>
    <hr class="solid-thin mt-0 mb-0">
    ${devices}
  </div>`;
}

function getGMapPopupTrafficSiteContent(site) {

    let devices = ``
    site.deviceNames.forEach(devName => {
        devices += `<div>${devName}</div>`
    })

    return `<div class="m-0 p-0" style="min-width: 250px; max-width: 350px; font: 12px/20px 'Barlow', sans-serif;">
    <div class="columns m-0 p-0 pt-2">
      <div class="column m-0 p-0">
        <div class="is-size-5 has-text-weight-semibold">${site.name}</div>
      </div>
    </div>
    <div class="mt-2">Devices: </div>
    <hr class="solid-thin mt-0 mb-0">
    ${devices}
  </div>`;
}

function getFormattedHours() {
    return [
        '00:00-01:00',
        '01:00-02:00',
        '02:00-03:00',
        '03:00-04:00',
        '04:00-05:00',
        '05:00-06:00',
        '06:00-07:00',
        '07:00-08:00',
        '08:00-09:00',
        '09:00-10:00',
        '10:00-11:00',
        '11:00-12:00',
        '12:00-13:00',
        '13:00-14:00',
        '14:00-15:00',
        '15:00-16:00',
        '16:00-17:00',
        '17:00-18:00',
        '18:00-19:00',
        '19:00-20:00',
        '20:00-21:00',
        '21:00-22:00',
        '22:00-23:00',
        '23:00-00:00'
    ]
}

function extractGMapGpsCoordinates(latLngString) {
    if (latLngString && latLngString.length > 0) {
        let value = latLngString.trim()
        let coordinates = value.split(',');

        try {
            let lat = parseFloat(coordinates[0].trim())
            let lng = parseFloat(coordinates[1].trim())
            return {lat: lat, lng: lng}
        } catch (e) {
            return null
        }

    }
}

function extractArrayGpsCoordinates(latLngString) {
    if (latLngString && latLngString.length > 0) {
        let value = latLngString.trim()
        let coordinates = value.split(',');

        try {
            let lat = parseFloat(coordinates[0].trim())
            let lng = parseFloat(coordinates[1].trim())
            return [lat, lng]
        } catch (e) {
            return null
        }

    }
}

function hasSubArray(master, sub) {
    let masterArray = master.sort()
    let subArray = sub.sort()
    return subArray.every((i => v => i = masterArray.indexOf(v, i) + 1)(0));
}

function isAllowed(me, requestedPerm) {
    let permNames = []
    if (me && me.permissions && me.permissions.length > 0) {
        permNames = me.permissions.map(perm => perm.name)
    }
    if (permNames.includes(requestedPerm)) {
        return true
    }
    return false
}

function groupPermissions(permissions) {

    console.log('Perms: ', permissions)

    let groupedPermissions = {}
    if (permissions && permissions.length > 0) {
        permissions.forEach(perm => {
            console.log('Perm: ', perm)
            if (perm.name) {
                let split = perm.name.split(':')

                if (split.length > 1) {
                    console.log('Perm split: ', split[0])
                    if (groupedPermissions[split[0]] !== undefined && Array.isArray(groupedPermissions[split[0]])) {
                        groupedPermissions[split[0]].push(perm)
                    } else {
                        groupedPermissions[split[0]] = []
                        groupedPermissions[split[0]].push(perm)
                    }
                }
            }

        })
    }

    console.log('Grouped: ', groupedPermissions)

    return groupedPermissions
}

function getDatesBetween(startDate, endDate, timings) {
    const dates = [];
    // Strip hours minutes seconds etc.
    let currentDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
    );

    while (currentDate <= endDate) {
        dates.push(currentDate);

        currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 1, // Will increase month if over range
        );
    }

    let newDates = []

    dates.forEach(date => {
        timings.forEach(timing => {
            let startTime = moment.utc(timing.startTime, 'hh:mm a');
            let endTime = moment.utc(timing.endTime, 'hh:mm a');

            let newDateStart = moment.utc([
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                startTime.get('hour'),
                startTime.get('minute'),
                startTime.get('second')
            ])

            let newDateEnd = moment.utc([
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                endTime.get('hour'),
                endTime.get('minute'),
                endTime.get('second')
            ])

            newDates.push({start: newDateStart.toDate(), end: newDateEnd.toDate()})
        })

    })

    return newDates;
}

function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
}

function mergeDeduplicate(arr) {
    return [...new Set([].concat(...arr))];
}

function convertCsvTemplatePeriods(dataString){

    let dataCollection

    if(dataString && dataString.length > 0){
        let split = dataString.split(',')
        if(split && split.length > 0){
            let dates = split[0]
            let times = split.slice(1, split.length);

            if(dates && dates.length > 0){
                console.log('DATES: ', dates)
                let datesSplit = dates.split('-')
                if(datesSplit && datesSplit.length > 1){
                    console.log('DATES SPLIT: ', datesSplit)
                    let partsStart = datesSplit[0].split('.')
                    let partsEnd = datesSplit[1].split('.')
                    let dateTimeStart = new Date(partsStart[2],partsStart[1]-1,partsStart[0])
                    dateTimeStart.setHours(0,0,0,0)
                    let dateTimeEnd = new Date(partsEnd[2], partsEnd[1]-1,partsEnd[0])
                    dateTimeEnd.setHours(0,0,0,0)

                    console.log('DATES START DATE: ', dateTimeStart)
                    console.log('DATES END DATE: ', dateTimeEnd)
                    dataCollection = {
                        dateRange: [dateTimeStart, dateTimeEnd],
                        timeRanges: []
                    }
                }
            }

            if(times && times.length > 0 && dataCollection && dataCollection.dateRange){
                console.log('DATES TIMES: ', times)
                times.forEach(timeStr => {
                    let timeSplit = timeStr.split('-')
                    if(timeSplit && timeSplit.length > 1){
                        let startTimeParts = timeSplit[0].split(':')
                        let endTimeParts = timeSplit[1].split(':')
                        if(startTimeParts && startTimeParts.length > 1 && endTimeParts && endTimeParts.length > 1){
                            let hoursStart = startTimeParts[0]
                            let minutesStart = startTimeParts[1]

                            let hoursEnd = endTimeParts[0]
                            let minutesEnd = endTimeParts[1]

                            let timeDateStart = new Date()
                            timeDateStart.setHours(hoursStart,minutesStart,0,0)

                            let timeDateEnd = new Date()
                            timeDateEnd.setHours(hoursEnd,minutesEnd,0,0)
                            dataCollection.timeRanges.push({startTime: timeDateStart, endTime: timeDateEnd})
                        }
                    }
                })
            }



        }
    }

    return dataCollection
}

module.exports = {
    risks,
    calculateRiskSumFromWeeklyReport,
    extractGMapGpsCoordinates,
    capitalizeFirstLetter,
    calculateToDcaFromWeeklyReport,
    getRiskRatingClass,
    calculateSimpleRisk,
    getMarkerClass,
    getMarkerIcon,
    getDeviceSvg,
    getRealDeviceSvg,
    getSeverityRatingScore,
    getDate,
    getDateObjectFromMelbourneUtcToLocal,
    getTime,
    getDateTime,
    getFormattedHours,
    getDirectionClass,
    getGmapPopupContent,
    getGmapPopupDeviceContent,
    getGMapPopupSitePlanContent,
    getGMapPopupTrafficSiteContent,
    dateToISOString,
    extractArrayGpsCoordinates,
    getSiteSvg,
    getSitePlanSvg,
    groupPermissions,
    hasSubArray,
    isAllowed,
    dateToISOStringEndOfDay,
    getDateRange,
    timeToString,
    getInputPeriod,
    convertPeriodToDateIsoStrings,
    parseTimeRanges,
    getDatesBetween,
    truncate,
    getDateTimeInTimeZone,
    formatTimeInTimeZone,
    startEndFromTimeZoneToIso,
    getGmapPopupQaContent,
    getDateInTimeZone,
    mergeDeduplicate,
    getPickerTimeToTimeZone,
    getGmapPopupDPContent,
    getVideoTimeFromLocal,
    getVideoTimeFromLocalToIsoString,
    formatUtcTime,
    convertCsvTemplatePeriods,
    VideoCalibrationCategory,
    appName,
    TemplateType,
    Role,
    Apps,
    DeviceType,
    DeviceStatus,
    gMapStyles,
    TaskType
}