<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <b-modal v-model="modalActive" width="50%" scroll="clip" style="z-index: 101" @close="clearFields()">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head" style="padding: 12px">
          <h6 class="is-size-5 ml-auto mr-auto pl-4 has-text-weight-semibold">Move Traffic Site</h6>
          <button
              type="button"
              class="delete"
              @click="show(false, deselectOnClose)"/>
        </header>
        <section class="modal-card-body p-0">
          <div>
            <div class="m-5">
              <b-field v-for="project in projects" :key="project._id">
                <b-radio v-model="projectChoice"
                         :native-value="project">
                  {{project.name}}
                </b-radio>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot" style="padding: 0">
          <b-button class="is-primary is-fullwidth m-2" @click="onMoveSite" rounded :disabled="projectChoice === null">
            Move Site
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "move-site-modal",
  data() {
    return {
      isLoading: false,
      modalActive: false,
      deselectOnClose: false,
      projectChoice: null,
      siteToMove: null
    }
  },
  methods:{
    ...mapActions({
      moveTrafficSite: 'projectsModule/moveTrafficSite',
    }),
    async onMoveSite(){
      console.log('On move site: ', this.siteToMove)
      let payload = {
        siteId: this.siteToMove.siteId,
        projectId: this.projectChoice._id,
        oldProjectId: this.selectedProject._id
      }

      console.log('On move site payload: ', payload)

      this.isLoading = true
      let response = await this.moveTrafficSite(payload)
      if (response instanceof Error) {
        this.showToast(response.message, 'is-danger')
      } else {

        this.showToast(`Traffic Site successfully moved to project with name "${this.projectChoice.name}"`, 'is-success')
        await this.clearFields()
        this.show(false)
      }
      this.isLoading = false

    },
    show(show, deselectOnClose, site) {
      this.siteToMove = site
      console.log('Site to move', site)
      this.modalActive = show
      this.deselectOnClose = deselectOnClose
      if (!this.modalActive) {
        this.clearFields()
      }
    },
    async clearFields() {
      this.projectChoice = null
      this.siteToMove = null
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    }
  },
  computed:{
    ...mapGetters({
      allProjects: 'projectsModule/getAllProjects',
      selectedProject: 'projectsModule/getSelectedProject'
    }),
    projects(){
      let projects = []
      this.allProjects.forEach(project => {
        if(project._id !== this.selectedProject._id){
          projects.push(project)
        }
      })

      return projects
    }
  }
}
</script>

<style scoped>

</style>