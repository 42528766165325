<template>
  <div class="card card-shadow p-2 mb-2" :key="deviceData.refresh + deviceData.videoPathRefresh">
    <div class="columns pl-2 pr-2 pt-1 pb-0">
      <div class="column is-3 ml-2">
        <div class="is-size-7 has-text-grey-light has-text-weight-normal">Site name</div>
        <div class="has-text-black has-text-weight-bold">{{ deviceData.siteName }}</div>
      </div>
      <div class="column is-1 m-0">
        <div class="is-size-7 has-text-grey-light has-text-weight-normal">Device</div>
        <div class="has-text-black has-text-weight-bold">{{ deviceData.name }}</div>
      </div>
      <hr class="mt-3 mb-4 has-background-grey-lighter mr-0" style="width: 1px; height: 48px">
      <div class="column m-0 ml-2 pl-1 pr-1" style="width: 110px; max-width: 110px" @click="onProcessingClick">
        <div class="has-text-centered clickable-info p-1">
          <div class="is-size-7 has-text-grey has-text-weight-normal">Processing time</div>
          <b-icon
              :type="deviceData.processingPeriods && deviceData.processingPeriods.length > 0 ? 'is-primary' : 'is-danger'"
              :icon="deviceData.processingPeriods && deviceData.processingPeriods.length > 0 ? 'check-circle' : 'close-circle'"
              size="is-small"></b-icon>
        </div>
      </div>
      <div class="column m-0 pl-1 pr-1" style="width: 110px; max-width: 110px" @click="onAiClick">
        <div class="has-text-centered clickable-info p-1">
          <div class="is-size-7 has-text-grey has-text-weight-normal">AI Config</div>
          <b-icon
              :type="deviceData.trackingConfig ? 'is-primary' : 'is-danger'"
              :icon="deviceData.trackingConfig ? 'check-circle' : 'close-circle'"
              size="is-small"></b-icon>
        </div>
      </div>
      <div class="column m-0 mr-2 pl-1 pr-1" style="width: 110px; max-width: 110px" @click="onVideoCalibrationClick">
        <div class="has-text-centered clickable-info p-1">
          <div class="is-size-7 has-text-grey has-text-weight-normal">Video Config</div>
          <b-icon
              :type="isVideoCalibrationExists() ? 'is-primary' : 'is-danger'"
              :icon="isVideoCalibrationExists() ? 'check-circle' : 'close-circle'"
              size="is-small"></b-icon>
        </div>
      </div>
      <hr class="mt-3 mb-4 has-background-grey-lighter mr-0" style="width: 1px; height: 48px">
      <full-validation-info ref="fullValidation"
                            v-if="deviceData.initialValidationData && deviceData.initialValidationData.fullTasks"
                            :initial-validation="deviceData.initialValidationData"
                            :refreshing="deviceData.refresh"
                            @onShowFullResults="onShowFullResults"
                            @onShowInitialResults="onShowInitialResults"
                            @onRunFullProcessing="onRunFullProcessing"
                            @onResetStep="onResetStep"
      >

      </full-validation-info>

      <ped-validation-info ref="pedValidation"
                           v-else-if="deviceData.initialValidationData && deviceData.initialValidationData.pedTasks"
                           :initial-validation="deviceData.initialValidationData"
                           :refreshing="deviceData.refresh"
                           @onShowFullResults="onShowFullResults"
                           @onShowInitialResults="onShowInitialResults"
                           @onRunFullProcessing="onRunFullProcessing"
                           @onRunPedestrianProcessing="onRunPedestrianProcessing"
                           @onResetStep="onResetStep"
      ></ped-validation-info>

      <initial-validation ref="initialValidation" v-else-if="deviceData.initialValidationData && deviceData.initialValidationData.tasks"
                          :initial-validation="deviceData.initialValidationData"
                          :refreshing="deviceData.refresh"
                          @onRunInitialValidation="onRunInitialValidation"
                          @onShowInitialResults="onShowInitialResults"
                          @onResetStep="onResetStep"
      >

      </initial-validation>
      <tasks-info ref="vehicleValidation" v-else :device-data="deviceData" :refreshing="deviceData.videoPathRefresh || deviceData.refresh" @onProcessingClick="onProcessingClick" @onAiClick="onAiClick"
                  @onRunTasksClick="onRunTasksClick"
                  @onAddTasksClick="onAddTasksClick" @onRunInitialValidation="onRunInitialValidation" @onResetStep="onResetStep"></tasks-info>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TasksInfo from "@/modules/data-processing-module/components/data-processing-card/tasks-info.vue";
import InitialValidation
  from "@/modules/data-processing-module/components/data-processing-card/initial-validation-info.vue";
import FullValidationInfo
  from "@/modules/data-processing-module/components/data-processing-card/full-validation-info.vue";
import PedValidationInfo
  from "@/modules/data-processing-module/components/data-processing-card/ped-validation-info.vue";

export default {
  name: "data-processing-card",
  components: {PedValidationInfo, FullValidationInfo, InitialValidation, TasksInfo},
  props: {
    deviceData: {
      type: Object
    },
    progress:{
      type: Number
    },
    progressStepOne:{
      type: Number
    },
  },
  methods: {
    ...mapActions({
      setSelectedVideoCalibrations: 'dataProcessingModule/setSelectedVideoCalibrations',
      setSelectedProcessingDevice: 'dataProcessingModule/setSelectedProcessingDevice'
    }),
    getMessage() {
      if (this.allConditions()) {
        return 'This device has no tasks'
      } else {
        return 'Conditions missing'
      }
    },
    setProgress(progress){
      if(this.$refs.initialValidation){
        this.$refs.initialValidation.setProgress(progress)
      }

      if(this.$refs.fullValidation){
        this.$refs.fullValidation.setProgress(progress)
      }

      if(this.$refs.pedValidation){
        this.$refs.pedValidation.setProgress(progress)
      }
    },
    setProgressStepOne(progress){
      if(this.$refs.vehicleValidation){
        this.$refs.vehicleValidation.setProgress(progress)
      }
    },
    onProcessingClick() {
      console.log('On processing click')
      this.$emit('onProcessingClick', this.deviceData)
    },
    onAiClick() {
      console.log('On AI click')
      this.$emit('onAiClick', this.deviceData)
    },
    onVideoCalibrationClick() {
      console.log('On Video calibration click: ', this.deviceData)
      this.setSelectedVideoCalibrations(this.deviceData.videoCalibrations)
      this.setSelectedProcessingDevice(this.deviceData)
      //this.$router.push('/video-calibration')

      const params = {
        projectId: this.selectedProcessingProject.project._id,
        dataDeviceId: this.deviceData._id
      }

      console.log('Params: ', params)

      this.$router.push({name: 'video-calibration', params: params})
    },
    showToast(message, type) {
      this.$buefy.toast.open({
        message: message,
        duration: 3000,
        type: type
      })
    },
    statusStyle() {
      if (this.deviceData.tasks && this.deviceData.tasks.status) {
        if (this.deviceData.tasks.status === 'CREATED') {
          return {background: '#8C84FF'}
        } else if (this.deviceData.tasks.status === 'RUNNING') {
          return {background: '#D8BA13'}
        } else if (this.deviceData.tasks.status === 'COMPLETED') {
          return {background: '#1CAB80'}
        }
      }
      return {background: '#000000'}
    },
    isVideoCalibrationExists() {
      if (this.deviceData.videoCalibrations && this.deviceData.videoCalibrations.length > 0) {
        if (this.deviceData.videoCalibrations.filter(calibration => calibration.status === 'active').length > 0) {
          return true
        }
      }
      return false
    },
    allConditions() {
      return this.isVideoCalibrationExists() && this.deviceData.processingPeriods && this.deviceData.processingPeriods.length > 0 && this.deviceData.trackingConfig
    },
    onAddTasksClick() {
      console.log('On Add task clicked')
      this.$emit('onAddTasksClick', this.deviceData)
    },
    onRunTasksClick() {
      console.log('On Run task clicked')
      this.$emit('onRunTasksClick', this.deviceData)
    },
    onRunInitialValidation() {
      this.$emit('onRunInitialValidation', this.deviceData)
    },
    onRunFullProcessing() {
      this.$emit('onRunFullProcessing', this.deviceData)
    },
    onRunPedestrianProcessing() {
      this.$emit('onRunPedestrianProcessing', this.deviceData)
    },
    onShowInitialResults(){
      this.$emit('onShowInitialResults', this.deviceData)
    },
    onShowFullResults(){
      this.$emit('onShowFullResults', this.deviceData)
    },
    onResetStep(stepName){
      this.$emit('onResetStep', {stepName: stepName, dataDeviceId: this.deviceData._id})
    }
  },
  computed: {
    ...mapGetters({
      selectedProcessingProject: 'dataProcessingModule/getSelectedProcessingProject',
      selectedDevice: 'dataProcessingModule/getSelectedProcessingDevice'
    }),
  },
  watch:{
    progress: {
      handler: function (after, before) {
        this.setProgress(after)
      },
      deep: true
    },
    progressStepOne: {
      handler: function (after, before) {
        this.setProgressStepOne(after)
      },
      deep: true
    },
    refresh: {
      handler: function (after, before) {
        //this.setRefreshing(after)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.card-shadow {
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  background-color: white;
  border-radius: 0.35rem;
}

.clickable-info {
  background: #f4f4f4;
  border-radius: 0.3rem;
}

.clickable-info:hover {
  cursor: pointer;
  box-shadow: 0 0.125em 0.125em -0.125em rgb(10 10 10 / 20%), 0 0px 0 1px rgb(10 10 10 / 5%);
  background: #fcfcfc;
}

.clickable:hover {
  cursor: pointer;
}

.dashed-border-small {
  padding: 8px 8px;
  border-style: dashed;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}
</style>