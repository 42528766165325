<template>
  <div style="margin-top: 12px">
    <b-tooltip style="margin-top: 24px; margin-right: 16px" v-if="!connected"
               label="Live updates inactive. Click to reconnect." position="is-left"
               type="is-dark">
      <b-button icon-right="wifi-strength-alert-outline" @click="createConnection"
                type="is-danger" outlined size="is-small"></b-button>
    </b-tooltip>

    <b-tooltip v-else style="margin-top: 24px; margin-right: 16px" label="Live updates active" position="is-left"
               type="is-dark">

      <b-button icon-right="wifi" size="is-small" @click="destroyConnection()"
                type="is-primary"></b-button>
    </b-tooltip>
    <div>{{ mqttMessage }}</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'web-socket',
  data() {
    return {
      socket: null,
      connected: false,
      date: new Date(),
      connectionId: null,
      focused: false
    };
  },
  mounted() {
    this.focused = true
    setTimeout(() => {
      this.createConnection()
    }, 200)

    let context = this

    window.onblur = function () {
      context.focused = false
      // tab is changed
    };
    window.onfocus = function () {
      context.focused = true
      context.createConnection()
      // tab is active
    };

  },
  beforeDestroy() {
    console.log('Before destroy')
    this.destroyConnection()
    console.log('Destroyed')
  },
  methods: {
    ...mapActions({
      setSelectedProject: 'projectsModule/setSelectedProject',
      fetchAllProjects: 'projectsModule/getAllProjects'
    }),
    async createConnection() {
      console.log('Create connection called')
      if (this.$store.state.mqttStatus !== 'connected') {
        await this.$store.dispatch('setMqttStatus', 'connecting')
        let user = await this.$auth.currentAuthenticatedUser()
        if(user && user.signInUserSession && user.signInUserSession.accessToken){
          console.log('Current user', user)
          this.createSocket(user.signInUserSession.accessToken.jwtToken)
        }
      }
    },
    createSocket(token){
      this.socket = new WebSocket(`${this.$env.VUE_APP_WEB_SOCKET_URL}?Auth=${token}`)

      this.socket.onerror = (event) => {
        console.log('On error: ', event)
        this.connected = false
        this.$store.dispatch('setMqttStatus', 'disconnected')
      };

      this.socket.onclose = (event) => {
        console.log('On close: ', event)
        this.connected = false
        this.$store.dispatch('setMqttStatus', 'disconnected')
        if(this.focused){
          this.createConnection()
        }
      }

      this.socket.onopen = (event) => {
        console.log('On open: ', event)
        this.connected = true
        this.$store.dispatch('setMqttStatus', 'connected')

        if (this.selectedProject && this.selectedProject._id) {
          console.log('Selected project on open:', this.selectedProject)
          this.doSubscribe(this.selectedProject._id)
        }
      };

      this.socket.onmessage = async (event) => {
        console.log('Received: ', event.data)
        try {
          let dt = JSON.parse(event.data)
          if (dt && dt.connectionId) {
            console.log('Success subscribe: ', dt)
            this.connectionId = dt.connectionId
          } else if (dt && dt.resId && dt.changedBy && dt.fromWhere && dt.fromConnection) {
            if (dt.fromConnection !== this.connectionId) {
              let msg = ''
              console.log('Received Resource Change', dt.resId)
              if (this.allProjects) {
                let projectIndex = this.allProjects.findIndex(project => project._id === dt.resId)
                if (projectIndex === -1) {
                  console.log('New project added')
                  await this.fetchAllProjects()
                  msg = 'System Syncing...'
                }
              }
              if (this.selectedProject && this.selectedProject._id === dt.resId) {
                await this.setSelectedProject({_id: dt.resId})
                msg = 'System Syncing...'
              }
              if (msg !== '') {
                this.$buefy.toast.open({
                  message: msg,
                  duration: 2000,
                  type: 'is-primary'
                })
              }

            }
          }
        } catch (e) {
          console.log(e)
        }

      };
    },
    destroyConnection() {
      if (this.connected) {
        console.log('Call Destroy')
        this.socket.close()
        this.connected = false
        this.$store.dispatch('setMqttStatus', 'disconnected')
        console.log('Successfully disconnected!')
      } else {
        this.$store.dispatch('setMqttStatus', 'disconnected')
        console.log('Already disconnected!')
      }
    },
    doUnSubscribe(project) {
      console.log('Call Unsubscribe')
      if (this.connected && project && project._id) {
        let message = '{"action":"unsubscribe", "message":{"objectId":"' + project._id + '"}}'
        this.socket.send(message)
        console.log('Call Unsubscribe Sent')
      }
    },
    doSubscribe(projectId) {
      console.log('Subscribe to project: ', projectId)
      if (this.connected) {
        let message = '{"action":"subscribe","message":{"objectId":"' + projectId + '","objectType":"project"}}'
        console.log('Subscribe to project msg:', message)
        this.socket.send(message)
      }
    },
    sendEvent(resId) {
      let payload = {
        resId: resId,
        changedBy: this.$store.state.user.username,
        fromWhere: 'web',
        objectType: 'project'
      }

      let message = JSON.stringify(payload)

      this.$store.commit('setMqttEventToSend', null)

      if (this.socket && this.connected) {
        let msg = '{"action":"broadcast","message":' + message + '}'
        console.log('Event to send: ', msg)
        this.socket.send(msg)
      }
    },

  },
  computed: {
    ...mapGetters({
      selectedProject: 'projectsModule/getSelectedProject',
      mqttMessage: 'getMqttEventToSend',
      allProjects: 'projectsModule/getAllProjects',
      accessToken: 'accessToken'
    }),
  },
  watch: {
    mqttMessage: {
      handler: function (after, before) {
        console.log('Event to send: ', after, before)
        if (after !== null) {
          this.sendEvent(after)
        }
      },
      deep: true,
    },
    selectedProject: {
      handler: function (after, before) {
        console.log('Event to send: ', after, before)
        if (this.connected) {
          //unselect project
          if ((after === null && before) || (after && before && after._id !== before._id)) {
            this.doUnSubscribe(before)
          }

          if ((after && after._id && before === null) || (after && before && after._id !== before._id)) {
            this.doSubscribe(after._id)
          }
        } else {
          this.createConnection()
        }
      },
      deep: true,
    },
    accessToken: {
      handler: function (after, before) {
        console.log('Access token changed: ', after, before)
        this.destroyConnection()
        this.createConnection()

      },
      deep: true,
    }
  }

};
</script>

<style scoped>

</style>